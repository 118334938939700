import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { portfolioListsState } from '../../states/states';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function CryptocurrencyWalletSolution({ index }) {
   const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
   return (
      <>
         {/* <!-- Content --> */}
         <div className="content-wrapper">
            {/* <!-- Lines --> */}
            <section className="content-lines-wrapper">
               <div className="content-lines-inner">
                  <div className="content-lines"></div>
               </div>
            </section>
            <HeaderBanner />
            {/* <!-- Project Page  --> */}
            <section className="section-padding2 portfolio-info portfolio-info-mobile">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2 className="section-title2 text-left">암호화폐 지갑 솔루션</h2>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-8">
                        <p className="text-left">
                           본사는 다양한 지갑 솔루션을 보유하고 있으며, 다양한 기능이 포함된 암호화폐 지갑을 구축 및 운영 중 입니다. 
                           <br/><br/>
                           고객이 원하시는 운영 방향에 따라 시스템 설계를 중앙화, 탈중앙화로 모두 구현 가능합니다. 
                           또한 다국어 지원, 토큰 간 스왑 지원, DID, KYC 인증, OTP 등 다양한 서브 기능들이 포함되어 있습니다. 
                           더불어 암호화폐 지갑과 외부 서비스를 연동하여 인앱결제를 지원하는 솔루션도 제공하고 있습니다. 
                           이를 통해 지갑 기능뿐만이 아닌 플랫폼으로서 독자적인 브랜드로 서비스 하실 수 있습니다.
                           <br/><br/>
                           포트폴리오에 관련된 구체적인 내용은 보안 상의 이유로 오프라인 미팅을 통해서만 제공 가능합니다.
                        </p>
                     </div>
                     <div className="col-md-4 text-left">
                        <p><b>Type : </b> Wallet Solution</p>
                        <p><b>Release Date : </b> 2020.09. ~ 2020.11.</p>
                        <p><b>Client : </b>  Anonymous</p>
                        {/* <p><b>Download(Google PlayStore) : </b> <a className="portfolio-link" href="https://play.google.com/store/apps/details?id=com.bizauto.bizaplatform">LINK</a></p> */}
                     </div>
                  </div>
                  <div className="row mt-30">
                     <div className="col-md-6 gallery-item">
                        <a href={images.wallet[0]} title="Cryptocurrency Wallet Solution" className="img-zoom">
                           <div className="gallery-box">
                           <div className="gallery-img"> <img src={images.wallet[0]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                           </div>
                        </a>
                     </div>
                     <div className="col-md-6 gallery-item">
                        <a href={images.wallet[1]} title="Cryptocurrency Wallet Solution" className="img-zoom">
                           <div className="gallery-box">
                           <div className="gallery-img"> <img src={images.wallet[1]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                           </div>
                        </a>
                     </div>
                     <div className="col-md-6 gallery-item">
                        <a href={images.wallet[2]} title="Cryptocurrency Wallet Solution" className="img-zoom">
                           <div className="gallery-box">
                           <div className="gallery-img"> <img src={images.wallet[2]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                           </div>
                        </a>
                     </div>
                     <div className="col-md-6 gallery-item">
                        <a href={images.wallet[3]} title="Cryptocurrency Wallet Solution" className="img-zoom">
                           <div className="gallery-box">
                           <div className="gallery-img"> <img src={images.wallet[3]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                           </div>
                        </a>
                     </div>
                     <div className="col-md-6 gallery-item">
                        <a href={images.wallet[4]} title="Cryptocurrency Wallet Solution" className="img-zoom">
                           <div className="gallery-box">
                           <div className="gallery-img"> <img src={images.wallet[4]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                           </div>
                        </a>
                     </div>
                  </div>
               </div>
            </section>
            <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
         </div>
      </>
   );
}