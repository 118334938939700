import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { portfolioListsState } from '../../states/states';
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function Fitdoc({ index }) {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
      {/* <!-- Content --> */}
      <div className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </section>
        <HeaderBanner />
        {/* <!-- Project Page  --> */}
        <section className="section-padding2 portfolio-info portfolio-info-mobile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="section-title2 text-left">구독형 운동처방 서비스</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <p className="text-left">
                  FITDOC은 Exercise Prescription B2B Subscribe Solution으로 개발된 Mobile Application입니다. 
                  <br/><br/>
                  임직원 근골격계 건강 평가, 관리서비스를 b2b로 제공하는 업체입니다. "기능 평가"라는 근골격계의 정상적인 움직임에서 문제점을 포착하고 이를 지속적으로 관리할 수 있는 일련의 서비스를 솔루션으로 제공합니다. 
                  <br/><br/>
                  2023년에는 해당 서비스의 온라인 예약 홈페이지도 추가 개발 완료되었습니다.
                </p>
              </div>
              <div className="col-md-4 text-left">
                <p><b>Type : </b> B2B Subscribe Solution</p>
                <p><b>Release Date : </b> 2022.10. ~ 2022.12.</p>
                <p><b>Service : </b>  Mobile App / Web Admin</p>
                <p><b>Client : </b>  핏닥</p>
                <p><b>링크 : </b> <a className="portfolio-link" href="https://www.fitdoc.co.kr" target="_blank">LINK1</a> <a className="portfolio-link" href="https://play.google.com/store/apps/details?id=com.fit.doc&pcampaignid=web_share" target="_blank">LINK2</a></p>
              </div>
            </div>
            <div className="row mt-30">
              {
                images.fitdoc.map(img => (
                  <div className="col-md-6 gallery-item">
                    <a href={img} title="Fitdoc" className="img-zoom">
                      <div className="gallery-box">
                        <div className="gallery-img"> <img src={img} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                      </div>
                    </a>
                  </div>
                ))
              }
            </div>
          </div>
        </section>
        <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
      </div>
    </>
  );
}