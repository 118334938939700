export function HeaderBanner() {
   return (
      <>
         {/* <!-- Header Banner --> */}
         <section className="banner-header banner-img valign bg-img bg-fixed" data-overlay-darkgray="1" data-background="https://res.cloudinary.com/bizamall/image/upload/v1710957668/hug/assets/home/home_banner_lescn9.png">
            {/* <!-- Left Panel --> */}
            <div className="left-panel"></div>
         </section>
      </>
   );
}