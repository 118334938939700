import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { portfolioListsState } from '../../states/states';
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function MyAuth({ index }) {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
    {/* <!-- Content --> */}
    <div className="content-wrapper">
      {/* <!-- Lines --> */}
      <section className="content-lines-wrapper">
      <div className="content-lines-inner">
          <div className="content-lines"></div>
      </div>
      </section>
      <HeaderBanner />
      {/* <!-- Project Page  --> */}
      <section className="section-padding2 portfolio-info portfolio-info-mobile">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title2 text-left">DID 신원 인증 솔루션</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p className="text-left">
                MyAuth는 Decentralized Identity Solution으로 제작되었습니다. 개발 기간은 1달입니다.
                <br/>
                MyAuth는 블록체인과 DID(Decentralized Identifier, 분산신원증명시스템)기술을 활용해 다양한 증명서를 ONE-STOP으로 사용자의 단말에 직접 발급받아 저장하고, 필요시 수취기관에 제출해 위·변조 및 진위여부를 검증할 수 있는 모바일전자증명 플랫폼 입니다.
              </p>
            </div>
            <div className="col-md-4 text-left">
              <p><b>Type : </b>  Decentralized Identity</p>
              <p><b>Release Date : </b> 2023.04. ~ 2023.05.</p>
              <p><b>Service : </b> PC Web / Admin</p>
              <p><b>Client : </b> Anonymous</p>
            </div>
          </div>
          <div className="row mt-30">
            {
              images.myauth.map(img => (
                <div className="col-md-6 gallery-item">
                  <a href={img} title="MyAuth" className="img-zoom">
                    <div className="gallery-box">
                      <div className="gallery-img"> <img src={img} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                    </div>
                  </a>
                </div>
              ))
            }
          </div>
        </div>
        </section>
      <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
    </div>
    </>
  );
}