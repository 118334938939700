import logo from './logo.svg';
import './App.css';
import { Layout } from './Layout/Layout';
import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { useEffect } from 'react';


function App() {
  return (
    <RecoilRoot>
      <div className="App">
        <Layout />
      </div>
    </RecoilRoot>
  );
}

export default App;
