import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { portfolioListsState } from '../../states/states';
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function Naughtybomb({ index }) {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
    {/* <!-- Content --> */}
    <div className="content-wrapper">
      {/* <!-- Lines --> */}
      <section className="content-lines-wrapper">
      <div className="content-lines-inner">
          <div className="content-lines"></div>
      </div>
      </section>
      <HeaderBanner />
      {/* <!-- Project Page  --> */}
      <section className="section-padding2 portfolio-info portfolio-info-mobile">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title2 text-left">AI 사진 변환 솔루션</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p className="text-left">
                너티밤 AI 포토존은 AI Photo Conversion Solution으로 개발된 Web 입니다.
                <br/><br/>
                ■ 포토존에 설치된 기기로 사진을 찰칵!<br/>
                ■ 전화번호만 입력하면 카톡으로 결과물 바로 확인!<br/>
                ■ 젋은 고객들이 많은 오프라인 매장에서 손님들을 위한 색다른 이벤트를 제공하기 위해 제작된 서비스
                <br/><br/>
                ■ 간편하게 우리 매장 전용 포토존 세팅<br/>
                ■ 우리 매장, 작은 공간을 활용하여 색다른 이벤트를 진행할 수 있어요.
              </p>
            </div>
            <div className="col-md-4 text-left">
              <p><b>Type : </b> AI Photo Conversion</p>
              <p><b>Release Date : </b> 2023.12. ~ 2024.01.</p>
              <p><b>Service : </b> PC Web</p>
              <p><b>Client : </b> 너티밤 Naughty Bomb</p>
              <p><b>링크 : </b> <a className="portfolio-link" href="https://www.instagram.com/naughtybomb_bp?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">LINK</a></p>
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-md-6 gallery-item">
              <a href={images.naughtybomb[0]} title="Naughtybomb AI Photo Zone" className="img-zoom">
                <div className="gallery-box">
                  <div className="gallery-img"> <img src={images.naughtybomb[0]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                </div>
              </a>
            </div>
            <div className="col-md-6 gallery-item">
              <a href={images.naughtybomb[1]} title="Naughtybomb AI Photo Zone" className="img-zoom">
                <div className="gallery-box">
                  <div className="gallery-img"> <img src={images.naughtybomb[1]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        </section>
      <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
    </div>
    </>
  );
}