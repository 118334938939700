import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { useEffect, useMemo, useRef, useState } from "react";
import $ from "jquery";
import { HeaderBanner } from "../Layout/Header_Banner";
import { portfolioListsState, windowMaxWidthState } from '../states/states';
import Select from "react-select";
import emailjs from 'emailjs-com';

function ContactDetail() {
   return (
      <>
         <div className="contact-line"></div>
         <p><b>Contact Details</b></p>
         <p><b>Mail :</b> info@hugsoft.net</p>
      </>
   );
}

export function Contact() {
   const [windowMaxWidth, setWindowMaxWidth] = useRecoilState(windowMaxWidthState);
   const [windowWidth, setWindowWidth] = useState($(window).width());
   const options = useMemo(
      () => [
         { value: "blockChainCore", label: "BlockChain Core", after: "(가상자산, 전자지갑 개발)" },
         { value: "cryptoCurrencySolution", label: "Crypto Currency Solution", after: "(거래소, 솔루션 개발)" },
         { value: "web", label: "Web", after: "(웹 사이트 개발, 유지보수)" },
         { value: "app", label: "App", after: "(앱 개발, 유지보수)" },
         { value: "erp", label: "ERP", after: "" },
      ],
      []
   );
   const [values, setValues] = useState({ name: "", manager: "", phone: "", email: "", service: options[0].value + options[0].after, budget: "", inquiry: "" });
   //중복 제출 방지
   const [doubleSubmitFlag, setDoubleSubmitFlag] = useState(false);


   const customStyles = {
      option: (provided, state) => {
         const after = state.data.after !== "" ? state.data.after : "";
         return {
            ...provided,
            padding: "0 18px",
            height: 30,
            fontSize: windowWidth <= 375 ? 14 : 16,
            ':after': {
               content: `" ${after}"`,
               fontSize: windowWidth <= 375 ? 13 : 15,
            },
         }
      },
      control: () => ({
         // none of react-select's styles are passed to <Control />
         background: "#323232",
         display: "flex",
         color: '#999',
         padding: "6.5px 18px",
         paddingRight: 0,
      }),
      singleValue: (provided, state) => {
         const after = state.data.after !== undefined ? state.data.after : "";
         return {
            ...provided,
            color: '#999',
            padding: 0,
            margin: 0,
            ':after': {
               content: `" ${after}"`,
               fontSize: 15,
            },
         };
      },
      menu: (provided, state) => ({
         ...provided,
         width: windowWidth <= 375 ? 'max-content' : '100%',
         right: 0
      }),
      menuList: (provided, state) => ({
         ...provided,
         overflow: 'auto',
         color: '#999',
         background: "#323232",
         textAlign: 'start',
      }),
      indicatorSeparator: (provided, state) => ({
         ...provided,
         display: 'none'
      }),
      valueContainer: (provided, state) => ({
         ...provided,
         padding: 0,
      }),
      input: (provided, state) => ({
         ...provided,
         caretColor: 'transparent'
      }),
      dropdownIndicator: (provided, state) => ({
         ...provided,
         "svg": {
            display: 'none'
         },
      }),
   }

   //중복 제출 방지
   function doubleSubmitCheck() {
      if (doubleSubmitFlag) {
         return doubleSubmitFlag;
      } else {
         setDoubleSubmitFlag(true);
         return false;
      }
   }

   //input 값 수정
   function handleServiceChange(e) {
      setValues({ ...values, service: e.value + e.after });
   }

   function onChange(e) {
      const { name, value } = e.target;
      if (name === 'name') {
         setValues({ ...values, name: value });
      } else if (name === 'manager') {
         setValues({ ...values, manager: value });
      } else if (name === 'phone') {
         setValues({ ...values, phone: value });
      } else if (name === 'email') {
         setValues({ ...values, email: value });
      } else if (name === 'service') {
         setValues({ ...values, service: value });
      } else if (name === 'budget') {
         setValues({
            ...values, budget: value
         });
      } else if (name === 'inquiry') {
         setValues({ ...values, inquiry: value });
      }
   }

   const handleSubmit = (event) => {
      event.preventDefault();
      //중복 제출 방지
      if (doubleSubmitCheck()) return;

      // //emailjs -> 상세 설정 변경 (info@hugsoft.net)
      emailjs.send('service_3wnhsym', 'template_4gie9qs', {...values, phone: values.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) , budget: Number(values.budget).toLocaleString('ko-KR')}, 'LBtQ7bT0-rDdpddED')
         .then((result) => {
            // console.log(result.text);
            alert('제출되었습니다.');
            setDoubleSubmitFlag(false);
            setValues({ name: "", manager: "", phone: "", email: "", service: options[0].value + options[0].after, budget: "", inquiry: "" });
         }, (error) => {
            console.log(error.text);
            alert('입력한 내용을 확인해주세요.');
         });
   };

   return (
      <>
         {/* <!-- Content --> */}
         <div className="content-wrapper">
            {/* <!-- Lines --> */}
            <section className="content-lines-wrapper">
               <div className="content-lines-inner">
                  <div className="content-lines"></div>
               </div>
            </section>
            <HeaderBanner />
            {/* <!-- Contact --> */}
            <section className="section-padding2 contact-mobile">
               <div className="container text-left">
                  <div className="row mb-90">
                     {/* <!-- Logo --> */}
                     <div className="col-md-4 animate-box contact-content" data-animate-effect="fadeInUp">
                        {/* <img className="section-title-img" src="img/HUG/contact/contact_company_name.png" /> */}
                        <h2 className="section-title section-title-company"><span>Hugsoft</span></h2>
                        <h2 className="section-title">Contact <span>Us</span></h2>
                        <p className="contact-massege">당신의 소중한 서비스, 지금 전문가와 상담해보세요.</p>
                        {windowWidth > windowMaxWidth &&
                           <ContactDetail />
                        }
                     </div>
                     <div className="col-md-8 animate-box" data-animate-effect="fadeInUp">
                        <form onSubmit={handleSubmit} className="contact__form">
                           {/* <!-- Form elements --> */}
                           <div className="row">
                              <div className="col-md-12 form-group contact-form contact-form-input">
                                 <label htmlFor="name">회사명 *</label>
                                 <input name="name" id="name" type="text" placeholder="회사명을 입력해 주세요." value={values.name} onChange={onChange.bind(this)} required />
                              </div>
                              <div className="col-md-12 form-group contact-form contact-form-input">
                                 <label htmlFor="manager">담당자명 *</label>
                                 <input name="manager" id="manager" type="text" placeholder="담당자명을 입력해 주세요." value={values.manager} onChange={onChange.bind(this)} required />
                              </div>
                              <div className="col-md-12 form-group contact-form contact-form-input">
                                 <label htmlFor="phone">전화번호 *</label>
                                 <input name="phone" id="phone" type="text" pattern="^[0-9]+" placeholder="연락처를 입력해 주세요. (숫자)" value={values.phone} onChange={onChange.bind(this)} required />
                              </div>
                              <div className="col-md-12 form-group contact-form contact-form-input">
                                 <label htmlFor="email">이메일 *</label>
                                 <input name="email" id="email" type="email" placeholder="이메일을 입력해주세요." value={values.email} onChange={onChange.bind(this)} required />
                              </div>
                              <div className="col-md-12 form-group contact-form contact-form-input">
                                 <label htmlFor="service">서비스 종류</label>
                                 <Select className="service-selects" options={options} styles={customStyles} defaultValue={options[0]} onChange={handleServiceChange.bind(this)} />
                              </div>
                              <div className="col-md-12 form-group contact-form contact-form-input">
                                 <label htmlFor="budget">희망예산</label>
                                 <input name="budget" id="budget" type="text" placeholder="희망예산을 입력해주세요. (선택)" value={values.budget} onChange={onChange.bind(this)} />
                              </div>
                              <div className="col-md-12 form-group contact-form">
                                 <label htmlFor="inquiry">문의</label>
                                 {windowWidth > windowMaxWidth ?
                                    <textarea name="inquiry" id="inquiry" cols="30" rows="4" placeholder="문의 내용을 남겨주시면, 이메일로 답변드리겠습니다." value={values.inquiry} onChange={onChange.bind(this)}></textarea> :
                                    <textarea name="inquiry" id="inquiry" cols="30" rows="4" placeholder="문의 내용을 남겨주시면, &#13;&#10;이메일로 답변드리겠습니다." value={values.inquiry} onChange={onChange.bind(this)}></textarea>}
                              </div>
                              <div className={windowWidth > windowMaxWidth ? 'col-md-12 text-right' : 'col-md-12 text-center mt-10'}>
                                 <input className="contact-submit" name="submit" type="submit" value="SUBMIT" />
                              </div>
                           </div>
                        </form>
                     </div>
                     {windowWidth <= windowMaxWidth &&
                        <div className="contact-detail">
                           <ContactDetail />
                        </div>
                     }
                  </div>
               </div>
            </section>
         </div>
      </>
   );
}