import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { portfolioListsState } from '../../states/states';
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function Sasurae({ index }) {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
    {/* <!-- Content --> */}
    <div className="content-wrapper">
      {/* <!-- Lines --> */}
      <section className="content-lines-wrapper">
      <div className="content-lines-inner">
          <div className="content-lines"></div>
      </div>
      </section>
      <HeaderBanner />
      {/* <!-- Project Page  --> */}
      <section className="section-padding2 portfolio-info portfolio-info-mobile">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title2 text-left">실시간 화상 강의 솔루션</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p className="text-left">
                사수래는 Online Education Real-time video lecture solution으로 제작되었습니다. 개발 기간은 2달이며, 이후 지속적으로 유지 보수 및 추가 개발을 맡아서 진행하고 있습니다.
                <br/><br/>
                [서비스 내용]<br/>
                4개의 Web Platform(Admin · 기업 · 멘토 · 멘티) / 멘토링 예약 자동화 시스템 / 1:1 개별 화상 멘토링 / 그룹 멘토링 / 화상강의·화면 공유·STT  기능 제공 / 한 눈에 볼 수 있는 통계
              </p>
            </div>
            <div className="col-md-4 text-left">
              <p><b>Type : </b>  Real-time video lecture</p>
              <p><b>Release Date : </b> 2023.05. ~ 2023.11.</p>
              <p><b>Service : </b> PC Web·Admin / Mobile Web</p>
              <p><b>Client : </b> INTWEEN</p>
              <p><b>링크 : </b> <a className="portfolio-link" href="https://sasurae.kr/sasurae/main.do" target="_blank">LINK</a></p>
            </div>
          </div>
          <div className="row mt-30">
            {
              images.sasurae.map(img => (
                <div className="col-md-6 gallery-item">
                  <a href={img} title="Sasurae" className="img-zoom">
                    <div className="gallery-box">
                      <div className="gallery-img"> <img src={img} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                    </div>
                  </a>
                </div>
              ))
            }
          </div>
        </div>
        </section>
      <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
    </div>
    </>
  );
}