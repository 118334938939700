import { HeaderBanner } from "../Layout/Header_Banner";

export function Services() {
   return (
      <>
         {/* <!-- Content --> */}
         <div className="content-wrapper">
            {/* <!-- Lines --> */}
            <section className="content-lines-wrapper">
               <div className="content-lines-inner">
                  <div className="content-lines"></div>
               </div>
            </section>
            <HeaderBanner />
            {/* <!-- Services --> */}
            <section className="services section-padding2 services-mobile">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12" style={{ textAlign: 'start' }}>
                        <h2 className="section-title">OUR <span>SERVICES</span></h2>
                     </div>
                  </div>
                  <div className="row text-left">
                     <div className="col-md-4">
                        <div className="item">
                           <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960196/hug/assets/home/blockchain_core_ken456.png" alt="" />
                           <h5>BLOCKCHAIN CORE</h5>
                           <div className="line"></div>
                           <div className="services-content">
                              <ul>
                                 <li>블록체인 노드 구축</li>
                                 <li>신규 가상화폐 개발 / 시장조사 및 플랜</li>
                                 <li>백서 작성 : 코인 정책 및 블록체인 시스템 연계 - 알고리즘 및 구조화</li>
                                 <li>윈도우 및 모바일 전자지갑 개발</li>
                                 <li>블록체인 탐색기 / RPC 통신 모듈 개발</li>
                              </ul>
                              <div className="numb">01</div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4">
                        <div className="item">
                           <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960254/hug/assets/home/chypto_currency_solution_wjfq5i.png" alt="" />
                           <h5>CRYPTO CURRENCY SOLUTION</h5>
                           <div className="line"></div>
                           <div className="services-content">
                              <ul>
                                 <li>가상화폐 거래소/교환소 개발</li>
                                 <li>가상화폐 P2P 거래소 개발</li>
                                 <li>가상화폐 PAY 솔루션 개발</li>
                                 <li>외부 연동 Restful Webservice(API) 모듈 개발</li>
                              </ul>
                              <div className="numb">02</div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4">
                        <div className="item">
                           <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960210/hug/assets/home/ai_big_data_zbj2he.png" alt="" />
                           <h5>AI · BIG DATA</h5>
                           <div className="line"></div>
                           <div className="services-content">
                              <ul>
                                 <li>최신 통계, 데이터마이닝 및 머신러닝 기법</li>
                                 <li>자가학습을 통한 지속적인 품질 향상</li>
                                 <li>기계 독해(MRC), 텍스트 분류(XDC)</li>
                                 <li>자연어 이해(NLU)</li>
                                 <li>문서 인식, 챗봇</li>
                              </ul>
                              <div className="numb">03</div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="row" style={{ textAlign: 'start' }}>
                     <div className="col-md-4">
                        <div className="item">
                           <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960214/hug/assets/home/web_gkv5kx.png" alt="" />
                           <h5>WEB</h5>
                           <div className="line"></div>
                           <div className="services-content">
                              <ul>
                                 <li>기업 홈페이지 제작</li>
                                 <li>웹 크롤링 및 HTML 파싱</li>
                                 <li>다양한 OS, 플랫폼에서의 웹서버 구축</li>
                                 <li>강력한 성능의 WAS</li>
                                 <li>사용자 경험 기반 반응형 웹사이트</li>
                              </ul>
                              <div className="numb">04</div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4">
                        <div className="item">
                           <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960249/hug/assets/home/app_oxksdi.png" alt="" />
                           <h5>APP</h5>
                           <div className="line"></div>
                           <div className="services-content">
                              <ul>
                                 <li>P2P, B2B 등 전문 마켓 플랫폼</li>
                                 <li>Social Commerce</li>
                                 <li>SNS - Social Network Service</li>
                                 <li>각종 Community 플랫폼</li>
                                 <li>Live Chat 플랫폼</li>
                              </ul>
                              <div className="numb">05</div>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-4">
                        <div className="item">
                           <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960334/hug/assets/home/erp_z4fmae.png" alt="" />
                           <h5>ERP</h5>
                           <div className="line"></div>
                           <div className="services-content">
                              <ul>
                                 <li>경영정보 시스템 - 인사, 급여, 근태, 회계, 영업, 재고관리</li>
                                 <li>생산공정 시스템 - 원자재 BOM, 불량률, 공정률</li>
                                 <li>다양한 사무 자동화 시스템</li>
                              </ul>
                              <div className="numb">06</div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            {/* <!-- Process --> */}
            <section className="process section-padding2 process-mobile">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2 className="section-title text-left">WORK <span>PROCESS</span> <span style={{ fontSize: 20 }}>(TECH STACK)</span></h2>
                     </div>
                  </div>
                  <div className="row text-center mt-60 mb-60">
                     <div className="col-md-3">
                        <div className="item first">
                           <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960340/hug/assets/home/line_down_o54ges.png" className="tobotm" alt="" />
                           <span className="icon icon-backgound" style={{ backgroundImage: "url('img/HUG/services/client_needs.png')" }}></span>
                           <div className="cont">
                              <h6 className="process-title">Client Needs</h6>
                              <div className="line"></div>
                              <p>프로그램에 대한 전문적인 지식이 없어도 고객 중심의 원활한 커뮤니케이션으로 CLIENT NEEDS의 디테일한 부분을 놓치지 않는 섬세한 서비스</p>
                              <h3>01</h3> </div>
                        </div>
                     </div>
                     <div className="col-md-3">
                        <div className="item odd mb-md50">
                           <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960340/hug/assets/home/line_down_o54ges.png" alt="" />
                           <span className="icon icon-backgound" style={{ backgroundImage: "url('img/HUG/services/direction.png')" }}></span>
                           <div className="cont">
                              <h3>02</h3>
                              <h6 className="process-title">Direction</h6>
                              <div className="line"></div>
                              <p>고객의 니즈를 정확하게 파악하고, 이를 바탕으로 프로젝트를 완성시키는 기획의 전문성과 진행중인 프로세스를 눈으로 확인 및 피드백 반영</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-3">
                        <div className="item">
                           <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960340/hug/assets/home/line_down_o54ges.png" className="tobotm" alt="" />
                           <span className="icon icon-backgound" style={{ backgroundImage: "url('img/HUG/services/build.png')" }}></span>
                           <div className="cont">
                              <h3>03</h3>
                              <h6 className="process-title">Build</h6>
                              <div className="line"></div>
                              <p>뛰어난 개발자들의 Man Power를 바탕으로 높은 퀄리티의 결과물 구현</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-3">
                        <div className="item odd">
                           <span className="icon icon-backgound" style={{ backgroundImage: "url('img/HUG/services/value.png')" }}></span>
                           <div className="cont">
                              <h3>04</h3>
                              <h6 className="process-title">Value</h6>
                              <div className="line"></div>
                              <p>웹/앱 서비스의 운영까지 고려한 지속적인 유지 보수</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </>
   );
}