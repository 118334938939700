import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { portfolioListsState } from '../../states/states';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function CryptoCurrencyLearningPlatformSolution({ index }) {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
      {/* <!-- Content --> */}
      <div className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </section>
        <HeaderBanner />
        {/* <!-- Project Page  --> */}
        <section className="section-padding2 portfolio-info portfolio-info-mobile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="section-title2 text-left">암호화폐 기반 온라인 강의 플랫폼</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <p className="text-left">
                암호화폐 기반 온라인 강의 플랫폼 솔루션을 구축 및 운영하였습니다.
                개발 기간은 3개월입니다.
                <br/><br/>
                모든 거래는 블록체인에 기록되도록 설계하여 사용자 간 투명한 거래를 보장하였습니다.
                결제한 강좌의 구매 내역 정보가 블록체인 원장에 기록되며, 원장에 기록된 정보들은 암호화되어 보관됩니다.
                강사와 수강생 회원 간 커뮤니케이션, 녹화 영상 강좌, 라이브 스트리밍 서비스 등 다양한 기능을 지원합니다.
                <br/><br/>
                포트폴리오에 관련된 구체적인 내용은 보안 상의 이유로 오프라인 미팅을 통해서만 제공 가능합니다.
                </p>
              </div>
              <div className="col-md-4 text-left">
                <p><b>Type : </b> E-Learning Platform</p>
                <p><b>Release Date : </b> 2020.11. ~ 2022.03.</p>
                <p><b>Client : </b> Anonymous</p>
                {/* <p><b>Download(Google PlayStore) : </b> <a className="portfolio-link" href="https://play.google.com/store/apps/details?id=com.amaxg.bizastudio">LINK</a></p> */}
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-6 gallery-item">
                <a href={images.elearning[0]} title="CryptoCurrency E-Learning Platform Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.elearning[0]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.elearning[1]} title="CryptoCurrency E-Learning Platform Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.elearning[1]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
      </div>
    </>
  );
}