import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { portfolioListsState } from '../../states/states';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function NftDexSolution({ index }) {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
      {/* <!-- Content --> */}
      <div className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </section>
        <HeaderBanner />
        {/* <!-- Project Page  --> */}
        <section className="section-padding2 portfolio-info portfolio-info-mobile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="section-title2 text-left">NFT 거래소 솔루션</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <p className="text-left">
                  탈중앙화 NFT 거래소 솔루션을 구축 및 운영하였습니다.
                  개발 기간은 4달입니다.
                  <br/><br/>
                  본사 지갑 솔루션과 결합하여 해당 거래소에서 구매한 NFT는 사용자의 지갑에 보관되고, 거래 또한 전적으로 사용자 간 지갑을 통해이루어지게끔 설계하였습니다.
                  또한, 스마트 컨트렉트를 통한 에스크로 제어로 거래, 경매를 안정적으로 서비스 합니다.
                  컬렉션 및 토큰의 생성은 거래소에서 소유하는것이 아닌 사용자의 지갑에서 소유하여 완벽한 탈중앙화 거래소로써 운영됩니다. 
                  그 외 NFT 등록/편집/구매, 경매, 경매입찰, Collection 및 Token 등록/관리, MetaMask 및 다양한 지갑과의 연동 등 보편화 되어있는 NFT 거래소의 모든 기능을 제공합니다.
                  <br/><br/>
                  포트폴리오에 관련된 구체적인 내용은 보안 상의 이유로 오프라인 미팅을 통해서만 제공 가능합니다.
                </p>
              </div>
              <div className="col-md-4 text-left">
                <p><b>Type : </b> NFT DEX Solution</p>
                <p><b>Release Date : </b> 2021.08. ~ 2021.11.</p>
                <p><b>Service : </b> PC Web / Mobile Web / Web Admin</p>
                <p><b>Client : </b> Anonymous</p>
                {/* <p><b>Web Address : </b> <a className="portfolio-link" href="http://uvit.bizauto.io">LINK</a></p> */}
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-6 gallery-item">
                <a href={images.nft[0]} title="NFT DEX Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.nft[0]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.nft[1]} title="NFT DEX Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.nft[1]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.nft[2]} title="NFT DEX Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.nft[2]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
      </div>
    </>
  );
}