import { HeaderBanner } from "../../Layout/Header_Banner";
import { images } from '../../constants/images';

export function Portfolio() {

  return (
    <>
      {/* <!-- Content --> */}
      <div className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </section>
        <HeaderBanner />
        {/* <!-- Projects --> */}
        <section className="projects section-padding2 portfolio-projects-mobile">
          <div className="container">
            <div className="row">
              <div className="col-md-12 animate-box" data-animate-effect="fadeInUp">
                <h2 className="section-title text-left">Our <span>Portfolio</span></h2> </div>
            </div>
            <div className="row">
            <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.eventplus[0]} alt="" />
                  </div>
                  <div className="con">
                    <h6><a href="/eventplus">Event outsourcing</a></h6>
                    <h5><a href="/eventplus">행사 아웃소싱 플랫폼</a></h5>
                    <div className="line"></div> <a href="/eventplus"><i className="ti-arrow-right"></i></a> </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.togethertraining[0]}alt="" />
                  </div>
                  <div className="con">
                    <h6><a href="/togethertraining">group training service</a></h6>
                    <h5><a href="/togethertraining">그룹 트레이닝 서비스 플랫폼</a></h5>
                    <div className="line"></div> <a href="/togethertraining"><i className="ti-arrow-right"></i></a> </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.sasurae[0]} alt="" />
                  </div>
                  <div className="con">
                    <h6><a href="/sasurae">Real-time video lecture</a></h6>
                    <h5><a href="/sasurae">실시간 화상 강의 솔루션</a></h5>
                    <div className="line"></div> <a href="/sasurae"><i className="ti-arrow-right"></i></a> </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.ssoc[0]} alt="" />
                  </div>
                  <div className="con">
                    <h6><a href="/ssoc">community platform</a></h6>
                    <h5><a href="/ssoc">커뮤니티 플랫폼 서비스</a></h5>
                    <div className="line"></div> <a href="/ssoc"><i className="ti-arrow-right"></i></a> </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.fitdoc[0]} alt="" />
                  </div>
                  <div className="con">
                    <h6><a href="/fitdoc">b2b subscribe solution</a></h6>
                    <h5><a href="/fitdoc">구독형 운동처방 서비스</a></h5>
                    <div className="line"></div> <a href="/fitdoc"><i className="ti-arrow-right"></i></a> </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.myauth[0]} alt="" />
                  </div>
                  <div className="con">
                    <h6><a href="/myauth">Decentralized identity</a></h6>
                    <h5><a href="/myauth">DID 신원 인증 솔루션</a></h5>
                    <div className="line"></div> <a href="/myauth"><i className="ti-arrow-right"></i></a> </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.game[0]} alt="" />
                  </div>
                  <div className="con">
                    <h6><a href="/game">web using record api</a></h6>
                    <h5><a href="/game">게임 API 연동 대회 홈페이지</a></h5>
                    <div className="line"></div> <a href="/game"><i className="ti-arrow-right"></i></a> </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.naughtybomb[0]} alt="" />
                  </div>
                  <div className="con">
                    <h6><a href="/naughtybomb">ai photo conversion</a></h6>
                    <h5><a href="/naughtybomb">AI 사진 변환 솔루션</a></h5>
                    <div className="line"></div> <a href="/naughtybomb"><i className="ti-arrow-right"></i></a> </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.tame[0]} alt="" />
                  </div>
                  <div className="con">
                    <h6><a href="/tame">tablet order integration</a></h6>
                    <h5><a href="/tame">매장 주문 통합 관리 솔루션</a></h5>
                    <div className="line"></div> <a href="/tame"><i className="ti-arrow-right"></i></a> </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.nft[0]} alt="" />
                  </div>
                  <div className="con">
                    <h6><a href="/nft_dex_solution">NFT DEX Solution</a></h6>
                    <h5><a href="/nft_dex_solution">NFT 거래소 솔루션</a></h5>
                    <div className="line"></div> <a href="/nft_dex_solution"><i className="ti-arrow-right"></i></a> </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.elearning[0]} alt="" /> </div>
                  <div className="con">
                    <h6><a href="/cryptoCurrency_learning_platform_solution">E-learning platform</a></h6>
                    <h5><a href="/cryptoCurrency_learning_platform_solution">암호화폐 기반 온라인 강의 플랫폼</a></h5>
                    <div className="line"></div> <a href="/cryptoCurrency_learning_platform_solution"><i className="ti-arrow-right"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.marketplace[0]} alt="" /> </div>
                  <div className="con">
                    <h6><a href="/cryptoCurrency_online_marketplace_solution">online marketplace</a></h6>
                    <h5><a href="/cryptoCurrency_online_marketplace_solution">암호화폐 기반 오픈마켓 솔루션</a></h5>
                    <div className="line"></div> <a href="/cryptoCurrency_online_marketplace_solution"><i className="ti-arrow-right"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.marketing[0]} alt="" /> </div>
                  <div className="con">
                    <h6><a href="/cryptoCurrency_marketing_solution">marketing solution</a></h6>
                    <h5><a href="/cryptoCurrency_marketing_solution">암호화폐 마케팅 솔루션</a></h5>
                    <div className="line"></div> <a href="/cryptoCurrency_marketing_solution"><i className="ti-arrow-right"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.exchange[0]} alt="" /> </div>
                  <div className="con">
                    <h6><a href="/cryptocurrency_exchange_solution">exchange solution</a></h6>
                    <h5><a href="/cryptocurrency_exchange_solution">암호화폐 거래소 솔루션</a></h5>
                    <div className="line"></div> <a href="/cryptocurrency_exchange_solution"><i className="ti-arrow-right"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.wallet[0]} alt="" /> </div>
                  <div className="con">
                    <h6><a href="/cryptocurrency_wallet_solution">Wallet solution</a></h6>
                    <h5><a href="/cryptocurrency_wallet_solution">암호화폐 지갑 솔루션</a></h5>
                    <div className="line"></div> <a href="/cryptocurrency_wallet_solution"><i className="ti-arrow-right"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 animate-box" data-animate-effect="fadeInUp">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={images.mainnet[0]} alt="" /> </div>
                  <div className="con">
                    <h6><a href="/blockchain_mainnet_development">BlockChain Mainnet</a></h6>
                    <h5><a href="/blockchain_mainnet_development">블록체인 메인넷 구축</a></h5>
                    <div className="line"></div> <a href="/blockchain_mainnet_development"><i className="ti-arrow-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}