import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { portfolioListsState } from '../../states/states';
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function EventPlus({ index }) {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
    {/* <!-- Content --> */}
    <div className="content-wrapper">
      {/* <!-- Lines --> */}
      <section className="content-lines-wrapper">
      <div className="content-lines-inner">
          <div className="content-lines"></div>
      </div>
      </section>
      <HeaderBanner />
      {/* <!-- Project Page  --> */}
      <section className="section-padding2 portfolio-info portfolio-info-mobile">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title2 text-left">행사 아웃소싱 플랫폼</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p className="text-left">
                이벤트플러스는 EVENT Outsourcing Service Platform입니다.<br/>
                개발 기간은 2달이며, 이후 지속적으로 유지 보수 및 추가 개발을 맡아서 진행할 예정입니다.
                <br/><br/>
                ■ 국내유일 이벤트 아웃소싱 플랫폼, 이벤트플러스 +<br/>
                ■ 클릭만으로 쉽고 빠른 행사 매칭! 행사 전문 업체의 견적서를 한 번에 제공<br/>
                ■ 검증된 업체 매칭과 쉽고 빠른 견적 제공 / 단 한 번의 행사등록으로 내 행사에 딱 맞는 업체를 매칭해주는 편리한 서비스<br/>
              </p>
            </div>
            <div className="col-md-4 text-left">
              <p><b>Type : </b>  Event Outsourcing</p>
              <p><b>Release Date : </b> 2023.11. ~ 2024.03.</p>
              <p><b>Service : </b> PC Web·Admin</p>
              <p><b>Client : </b> PLUSDOT</p>
              <p><b>링크 : </b> <a className="portfolio-link" href="https://eventplus.co.kr" target="_blank">LINK</a></p>
            </div>
          </div>
          <div className="row mt-30">
            {
              images.eventplus.map(img => (
                <div className="col-md-6 gallery-item">
                  <a href={img} title="Event Plus" className="img-zoom">
                    <div className="gallery-box">
                      <div className="gallery-img"> <img src={img} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                    </div>
                  </a>
                </div>
              ))
            }
          </div>
        </div>
        </section>
      <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
    </div>
    </>
  );
}