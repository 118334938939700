import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { portfolioListsState } from '../../states/states';
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function BlockChainMainnetDevelopment({ index }) {
   const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
   return (
      <>
         {/* <!-- Content --> */}
         <div className="content-wrapper">
            {/* <!-- Lines --> */}
            <section className="content-lines-wrapper">
               <div className="content-lines-inner">
                  <div className="content-lines"></div>
               </div>
            </section>
            <HeaderBanner />
            {/* <!-- Project Page  --> */}
            <section className="section-padding2 portfolio-info portfolio-info-mobile">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2 className="section-title2 text-left">블록체인 메인넷 구축</h2>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-8">
                        <p className="text-left">
                           고객의 요구사항에 맞는 블록체인 생태계 구성을 위한 맞춤형 블록체인 메인넷을 구축해드립니다.<br/>
                           이미 구축된 블록체인 메인넷의 토큰을 개발 및 운영하는 것 역시 지원 가능합니다. 
                           또한 포트폴리오에 소개된 프로젝트들을 이용한 서브 프로젝트를 통해 다양한 유형의 콘텐츠를 적용한 블록체인 상용 서비스 제공이 가능합니다.
                           <br/><br/>
                           프로젝트에 관련된 구체적인 내용은 보안 상의 이유로 오프라인 미팅을 통해서만 제공 가능합니다.
                        </p>
                     </div>
                     <div className="col-md-4 text-left">
                        <p><b>Type : </b> BlockChain Mainnet</p>
                        <p><b>Release Date : </b> 2021.04. ~ 2021.06. </p>
                        <p><b>Client : </b> Anonymous</p>
                        {/* <p><b>GitHub :  </b> <a className="portfolio-link" href="https://github.com/bizamainet/bizamainet">LINK</a></p>
                        <p><b>WhitePaper : </b> <a className="portfolio-link" href="https://amaxg.net/pdf/Whitepaper-en.pdf ">LINK</a></p> */}
                     </div>
                  </div>
                  <div className="row mt-30">
                     <div className="col-md-6 gallery-item">
                        <a href={images.mainnet[0]} title="Blockchain Mainnet Development" className="img-zoom">
                           <div className="gallery-box">
                              <div className="gallery-img"> <img src={images.mainnet[0]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                           </div>
                        </a>
                     </div>
                     <div className="col-md-6 gallery-item">
                        <a href={images.mainnet[1]} title="Blockchain Mainnet Development" className="img-zoom">
                           <div className="gallery-box">
                              <div className="gallery-img"> <img src={images.mainnet[1]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                           </div>
                        </a>
                     </div>
                     <div className="col-md-6 gallery-item">
                        <a href={images.mainnet[2]} title="Blockchain Mainnet Development" className="img-zoom">
                           <div className="gallery-box">
                              <div className="gallery-img"> <img src={images.mainnet[2]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                           </div>
                        </a>
                     </div>
                     <div className="col-md-6 gallery-item">
                        <a href={images.mainnet[3]} title="Blockchain Mainnet Development" className="img-zoom">
                           <div className="gallery-box">
                              <div className="gallery-img"> <img src={images.mainnet[3]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                           </div>
                        </a>
                     </div>
                     <div className="col-md-6 gallery-item">
                        <a href={images.mainnet[4]} title="Blockchain Mainnet Development" className="img-zoom">
                           <div className="gallery-box">
                              <div className="gallery-img"> <img src={images.mainnet[4]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                           </div>
                        </a>
                     </div>
                  </div>
               </div>
            </section>
            <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
         </div>
      </>
   );
}