import $ from "jquery";
import { navLinkState } from "../states/states"
import { useEffect } from 'react';

export function Header({ index }) {

   useEffect(() => {
      $(".nav-link").eq(index).addClass("active")
   }, []);

   return (
      <>
         {/* <!-- Preloader --> */}
         <div id="preloader"></div>
         {/* <!-- Progress scroll totop --> */}
         <div className="progress-wrap cursor-pointer">
            <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
               <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
            </svg>
         </div>
         {/* <!-- Navbar --> */}
         <nav className="navbar navbar-expand-lg">
            {/* <!-- Logo --> */}
            <div className="logo-wrapper valign">
               <div className="logo">
                  <a href="/">
                     <img src="https://res.cloudinary.com/bizamall/image/upload/v1710957121/hug/assets/home/HUGSOFT_logo_j5nfpt.png" className="logo-img" alt=""/>
                     <img src="https://res.cloudinary.com/bizamall/image/upload/v1710957198/hug/assets/home/HUGSOFT_name_yvkwhj.png" className="logo-img" alt=""/>
                     <img src="https://res.cloudinary.com/bizamall/image/upload/v1710957209/hug/assets/home/HUGSOFT_subname_yr53pj.png" className="logo-img" alt=""/>
                  </a>
               </div>
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
               aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{ padding: '5px 1px' }}>
               <span className="icon-bar" style={{ display: 'flex', flexDirection: 'column' }}>
                  <img src="https://res.cloudinary.com/bizamall/image/upload/v1710956511/hug/assets/mobile/nav_line_qsnami.png" style={{ marginBottom: 5 }}/>
                  <img src="https://res.cloudinary.com/bizamall/image/upload/v1710956511/hug/assets/mobile/nav_line_qsnami.png" style={{ marginBottom: 5 }}/>
                  <img src="https://res.cloudinary.com/bizamall/image/upload/v1710956511/hug/assets/mobile/nav_line_qsnami.png" />
               </span>
            </button>
            {/* <!-- Navbar links --> */}
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
               <ul className="navbar-nav ml-auto">
                  <li className="nav-item"><a className="nav-link" href="/" data-value="0" data-href="/">home</a></li>
                  <li className="nav-item"><a className="nav-link" href="about" data-value="1" data-href="about">ABOUT</a></li>
                  <li className="nav-item"><a className="nav-link" href="services" data-value="2">SERVICES</a></li>
                  <li className="nav-item"><a className="nav-link" href="portfolio" data-value="3">PORTFOLIO</a></li>
                  <li className="nav-item"><a className="nav-link" href="contact" data-value="4">CONTACT</a></li>
                  {/* <li className="nav-item"><a className="nav-link" href="faqs" data-value="5">FAQ</a></li> */}
               </ul>
            </div>
         </nav>
      </>
   );
}