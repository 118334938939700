export const images = {
    mainnet: [
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/mainnet/mainnet01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/mainnet/mainnet02.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/mainnet/mainnet03.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/mainnet/mainnet04.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/mainnet/mainnet05.png',
    ],
    wallet: [
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/wallet/wallet01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/wallet/wallet02.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/wallet/wallet03.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/wallet/wallet04.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/wallet/wallet05.png',
    ],
    exchange: [
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/exchange/exchange01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/exchange/exchange02.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/exchange/exchange03.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/exchange/exchange04.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/exchange/exchange05.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/exchange/exchange06.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/exchange/exchange07.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/exchange/exchange08.png',
    ],
    marketing: [
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/marketing/marketing01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/marketing/marketing02.png',
    ],
    marketplace: [
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/marketplace/marketplace01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/marketplace/marketplace02.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/marketplace/marketplace03.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/marketplace/marketplace04.png',
    ],
    elearning: [
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/elearning/elearning01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/elearning/elearning02.png',
    ],
    nft: [
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/nft/nft01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/nft/nft02.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/nft/nft03.png',
    ],
    ssoc: [
        'https://res.cloudinary.com/bizamall/image/upload/v1711361440/hug/assets/portfolio/ssoc/v2/ssoc01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361440/hug/assets/portfolio/ssoc/v2/ssoc02.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361440/hug/assets/portfolio/ssoc/v2/ssoc03.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361440/hug/assets/portfolio/ssoc/v2/ssoc04.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361440/hug/assets/portfolio/ssoc/v2/ssoc05.png',
    ],
    fitdoc: [
        'https://res.cloudinary.com/bizamall/image/upload/v1711362548/hug/assets/portfolio/fitdoc/v2/fitdoc01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711362548/hug/assets/portfolio/fitdoc/v2/fitdoc02.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711362548/hug/assets/portfolio/fitdoc/v2/fitdoc03.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711362548/hug/assets/portfolio/fitdoc/v2/fitdoc04.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711362548/hug/assets/portfolio/fitdoc/v2/fitdoc05.png',
    ],
    tame: [
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/tame/tame01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/tame/tame02.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/tame/tame03.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/tame/tame04.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/tame/tame05.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/tame/tame06.png',
    ],
    naughtybomb: [
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/naughtybomb/naughtybomb01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/naughtybomb/naughtybomb02.png',
    ],
    game: [
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/game/game01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1710957777/hug/assets/portfolio/game/game02.png',
    ],
    myauth: [
        'https://res.cloudinary.com/bizamall/image/upload/v1711362280/hug/assets/portfolio/myauth/v2/myauth01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711362280/hug/assets/portfolio/myauth/v2/myauth02.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711362280/hug/assets/portfolio/myauth/v2/myauth03.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711362280/hug/assets/portfolio/myauth/v2/myauth04.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711362280/hug/assets/portfolio/myauth/v2/myauth05.png',
    ],
    sasurae: [
        'https://res.cloudinary.com/bizamall/image/upload/v1711361909/hug/assets/portfolio/sasurae/v2/sasurae01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361909/hug/assets/portfolio/sasurae/v2/sasurae02.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361909/hug/assets/portfolio/sasurae/v2/sasurae03.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361909/hug/assets/portfolio/sasurae/v2/sasurae04.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361909/hug/assets/portfolio/sasurae/v2/sasurae05.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361909/hug/assets/portfolio/sasurae/v2/sasurae06.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361909/hug/assets/portfolio/sasurae/v2/sasurae07.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361909/hug/assets/portfolio/sasurae/v2/sasurae08.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361909/hug/assets/portfolio/sasurae/v2/sasurae09.png',
    ],
    togethertraining: [
        'https://res.cloudinary.com/bizamall/image/upload/v1711361673/hug/assets/portfolio/togethertraining/v2/togethertraining01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361673/hug/assets/portfolio/togethertraining/v2/togethertraining02.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361673/hug/assets/portfolio/togethertraining/v2/togethertraining03.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361673/hug/assets/portfolio/togethertraining/v2/togethertraining04.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361673/hug/assets/portfolio/togethertraining/v2/togethertraining05.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361673/hug/assets/portfolio/togethertraining/v2/togethertraining06.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711361673/hug/assets/portfolio/togethertraining/v2/togethertraining07.png',
    ],
    eventplus: [
        'https://res.cloudinary.com/bizamall/image/upload/v1711363080/hug/assets/portfolio/eventplus/v2/eventplus-01.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711363080/hug/assets/portfolio/eventplus/v2/eventplus02.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711363080/hug/assets/portfolio/eventplus/v2/eventplus03.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711363080/hug/assets/portfolio/eventplus/v2/eventplus04.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711363080/hug/assets/portfolio/eventplus/v2/eventplus05.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711363080/hug/assets/portfolio/eventplus/v2/eventplus06.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711363080/hug/assets/portfolio/eventplus/v2/eventplus07.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711363080/hug/assets/portfolio/eventplus/v2/eventplus08.png',
        'https://res.cloudinary.com/bizamall/image/upload/v1711363080/hug/assets/portfolio/eventplus/v2/eventplus09.png',
    ],
}