import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { portfolioListsState } from '../states/states';

import { Footer } from "./Footer";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";

import { Home } from "../pages/home";
import { About } from "../pages/about";
import { Services } from "../pages/services";
import { Portfolio } from "../pages/portfolio/index";
import { BlockChainMainnetDevelopment } from "../pages/portfolio/blockchain_mainnet_development";
import { CryptocurrencyWalletSolution } from "../pages/portfolio/cryptocurrency_wallet_solution";
import { CryptocurrencyExchangeSolution } from "../pages/portfolio/cryptocurrency_exchange_solution";
import { CryptoCurrencyMarketingSolution } from "../pages/portfolio/cryptoCurrency_marketing_solution";
import { CryptoCurrencyOnlineMarketplaceSolution } from "../pages/portfolio/cryptoCurrency_online_marketplace_solution";
import { CryptoCurrencyLearningPlatformSolution } from "../pages/portfolio/cryptoCurrency_learning_platform_solution";
import { NftDexSolution } from "../pages/portfolio/nft_dex_solution";
import { Ssock } from '../pages/portfolio/ssoc';
import { Fitdoc } from '../pages/portfolio/fitdoc';
import { Tame } from '../pages/portfolio/tame';
import { Naughtybomb } from '../pages/portfolio/naughtybomb';
import { Game } from '../pages/portfolio/game';
import { MyAuth } from '../pages/portfolio/myauth';
import { Sasurae } from '../pages/portfolio/sasurae';
import { TogetherTraining } from '../pages/portfolio/togethertraining';
import { EventPlus } from '../pages/portfolio/eventplus';
import { Contact } from "../pages/contact";
import { FAQ } from "../pages/faqs";

export function Layout() {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<><Header index={0} /><Sidebar /><Home /></>} />
          <Route exact path="/about" element={<><Header index={1} /><About /></>} />
          <Route exact path="/services" element={<><Header index={2} /><Services /></>} />
          <Route exact path="/portfolio" element={<><Header index={3} /><Portfolio /></>} />

          {/* portfolio 상세페이지 */}
          <Route path="/blockchain_mainnet_development" element={<><Header index={3} /><BlockChainMainnetDevelopment index={portfolioLists.findIndex(item => item === 'blockchain_mainnet_development')} /></>} />
          <Route path="/cryptocurrency_wallet_solution" element={<><Header index={3} /><CryptocurrencyWalletSolution index={portfolioLists.findIndex(item => item === 'cryptocurrency_wallet_solution')} /></>} />
          <Route path="/cryptocurrency_exchange_solution" element={<><Header index={3} /><CryptocurrencyExchangeSolution index={portfolioLists.findIndex(item => item === 'cryptocurrency_exchange_solution')} /></>} />
          <Route path="/cryptoCurrency_marketing_solution" element={<><Header index={3} /><CryptoCurrencyMarketingSolution index={portfolioLists.findIndex(item => item === 'cryptoCurrency_marketing_solution')} /></>} />
          <Route path="/cryptoCurrency_online_marketplace_solution" element={<><Header index={3} /><CryptoCurrencyOnlineMarketplaceSolution index={portfolioLists.findIndex(item => item === 'cryptoCurrency_online_marketplace_solution')} /></>} />
          <Route path="/cryptoCurrency_learning_platform_solution" element={<><Header index={3} /><CryptoCurrencyLearningPlatformSolution index={portfolioLists.findIndex(item => item === 'cryptoCurrency_learning_platform_solution')} /></>} />
          <Route path="/nft_dex_solution" element={<><Header index={3} /><NftDexSolution index={portfolioLists.findIndex(item => item === 'nft_dex_solution')} /></>} />
          <Route path="/ssoc" element={<><Header index={3} /><Ssock index={portfolioLists.findIndex(item => item === 'ssoc')} /></>} />
          <Route path="/fitdoc" element={<><Header index={3} /><Fitdoc index={portfolioLists.findIndex(item => item === 'fitdoc')} /></>} />
          <Route path="/tame" element={<><Header index={3} /><Tame index={portfolioLists.findIndex(item => item === 'tame')} /></>} />
          <Route path="/naughtybomb" element={<><Header index={3} /><Naughtybomb index={portfolioLists.findIndex(item => item === 'naughtybomb')} /></>} />
          <Route path="/game" element={<><Header index={3} /><Game index={portfolioLists.findIndex(item => item === 'game')} /></>} />
          <Route path="/myauth" element={<><Header index={3} /><MyAuth index={portfolioLists.findIndex(item => item === 'myauth')} /></>} />
          <Route path="/sasurae" element={<><Header index={3} /><Sasurae index={portfolioLists.findIndex(item => item === 'sasurae')} /></>} />
          <Route path="/togethertraining" element={<><Header index={3} /><TogetherTraining index={portfolioLists.findIndex(item => item === 'togethertraining')} /></>} />
          <Route path="/eventplus" element={<><Header index={3} /><EventPlus index={portfolioLists.findIndex(item => item === 'eventplus')} /></>} />

          <Route path="/contact" element={<><Header index={4} /><Contact /></>} />
          <Route path="/faqs" element={<><Header index={5} /><FAQ /></>} />

        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}