import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { portfolioListsState } from '../../states/states';
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function TogetherTraining({ index }) {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
    {/* <!-- Content --> */}
    <div className="content-wrapper">
      {/* <!-- Lines --> */}
      <section className="content-lines-wrapper">
      <div className="content-lines-inner">
          <div className="content-lines"></div>
      </div>
      </section>
      <HeaderBanner />
      {/* <!-- Project Page  --> */}
      <section className="section-padding2 portfolio-info portfolio-info-mobile">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title2 text-left">그룹 트레이닝 서비스 플랫폼</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p className="text-left">
                같이운동은 Group Training Service Platform입니다. 개발 기간은 2달이며, 이후 지속적으로 유지 보수 및 추가 개발을 맡아서 진행할 예정입니다.
                <br/><br/>
                [서비스 내용]<br/>
                3개의 Platform (Admin · TV App  ·  Mobile App) / 자유로운 운동 프로그램 커스텀 / 직관적인 TV App UI/UX / 간편한 프로그램 컨트롤러 APP
              </p>
            </div>
            <div className="col-md-4 text-left">
              <p><b>Type : </b>  Group Training Service</p>
              <p><b>Release Date : </b> 2023.10. ~ 2024.02.</p>
              <p><b>Service : </b> Mobile App / TV App / PC Admin </p>
              <p><b>Client : </b> 같이운동</p>
            </div>
          </div>
          <div className="row mt-30">
            {
              images.togethertraining.map(img => (
                <div className="col-md-6 gallery-item">
                  <a href={img} title="같이운동" className="img-zoom">
                    <div className="gallery-box">
                      <div className="gallery-img"> <img src={img} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                    </div>
                  </a>
                </div>
              ))
            }
          </div>
        </div>
        </section>
      <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
    </div>
    </>
  );
}