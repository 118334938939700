import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { portfolioListsState } from '../../states/states';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function CryptocurrencyExchangeSolution({ index }) {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
      {/* <!-- Content --> */}
      <div className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </section>
        <HeaderBanner />
        {/* <!-- Project Page  --> */}
        <section className="section-padding2 portfolio-info portfolio-info-mobile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="section-title2 text-left">암호화폐 거래소 솔루션</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <p className="text-left">
                  중앙화 암호화폐 거래소 기획·디자인·개발 및 유지보수 진행하였습니다.
                  개발 기간은 3개월 입니다.
                  <br/><br/>
                  비트코인, 이더리움, 고객사의 알트코인, 3개의 화폐들로 거래할 수 있는 각각의 마켓을 제공하였습니다. 
                  또한 다양한 알트코인들의 상장을 지원하였으며 많은 트래픽을 핸들링하며 안정적인 운영을 지원하였습니다.
                  <br/><br/>
                  포트폴리오에 관련된 구체적인 내용은 보안 상의 이유로 오프라인 미팅을 통해서만 제공 가능합니다.
                </p>
              </div>
              <div className="col-md-4 text-left">
                <p><b>Type : </b> Exchange Solution</p>
                <p>
                  <b>Release Date : </b> 2019.03. ~ 2021.12.
                  {/* <br /> <b style={{ visibility: 'hidden' }}>Release Date : </b>(Only Use Wallet Service) */}
                </p>
                <p><b>Client : </b> Anonymous</p>
                {/* <p><b>Web Address : </b> <a className="portfolio-link" href="http://bitfarm21.net">LINK</a></p> */}
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-6 gallery-item">
                <a href={images.exchange[0]} title="Cryptocurrency Exchange Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.exchange[0]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.exchange[1]} title="Cryptocurrency Exchange Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.exchange[1]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.exchange[2]} title="Cryptocurrency Exchange Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.exchange[2]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.exchange[3]} title="Cryptocurrency Exchange Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.exchange[3]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.exchange[4]} title="Cryptocurrency Exchange Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.exchange[4]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.exchange[5]} title="Cryptocurrency Exchange Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.exchange[5]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.exchange[6]} title="Cryptocurrency Exchange Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.exchange[6]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.exchange[7]} title="Cryptocurrency Exchange Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.exchange[7]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
      </div>
    </>
  );
}