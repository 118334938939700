import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { useEffect, useState } from "react";
import $ from "jquery";
import { portfolioListsState, windowMaxWidthState } from '../states/states';
import { images } from '../constants/images';

export function Home() {
  const [windowMaxWidth, setWindowMaxWidth] = useRecoilState(windowMaxWidthState);
  const [windowWidth, setWindowWidth] = useState($(window).width());

  return (
    <>
      {/* <!-- Content --> */}
      <div className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </section>
        {/* <!-- About --> */}
        <section className="about section-padding about-mobile">
          <div className="container">
            <div className="row text-left">
              <div className="col-md-8 mb-30 animate-box" data-animate-effect="fadeInUp">
                <h2 className="section-title">About <span>HUG SOFT</span></h2>
                <p className="about-title">[신뢰를 바탕으로 최상의 효율을 추구하다.]</p>
                <p className="about-content">
                  {windowWidth > windowMaxWidth ?
                    <>
                      <p>일의 주체는 사람이니, 사람의 성품을 살핌이 모든 성공의 첫걸음이라 생각합니다.</p>
                      <p>백장의 계약서보다 수천개의 계약 조항보다 사람이 행하는 신뢰(信賴) 하나가 더 가치 있다고 생각하며,</p>
                      <p>그 신뢰를 증명하기 위해 책임이란 무게를 짊어지고 무던히 걸어왔습니다. 신뢰있는 사람과의 약속 자체가</p>
                      <p style={{ marginBottom: 30 }}>곧 보증된 계약서임을 10년의 기간동안 증명하였고, 그렇게 쌓아온 신뢰가  새로운 성장 동력이 되었습니다.</p>
                    </> :
                    <>
                      <p style={{ marginBottom: 30 }}>
                        일의 주체는 사람이니, 사람의 성품을 살핌이 모든 성공의 첫걸음이라 생각합니다.
                        백장의 계약서보다 수천개의 계약 조항보다 사람이 행하는 신뢰(信賴) 하나가 더 가치 있다고 생각하며,
                        그 신뢰를 증명하기 위해 책임이란 무게를 짊어지고 무던히 걸어왔습니다.
                        신뢰있는 사람과의 약속 자체가 곧 보증된 계약서임을 10년의 기간동안 증명하였고,
                        그렇게 쌓아온 신뢰가  새로운 성장 동력이 되었습니다.
                      </p>
                    </>
                  }
                  <p>신뢰를 바탕으로 성공을 함께 설계하는 신의(信義)있는 파트너가 되겠습니다.</p>
                </p>
              </div>
              <div className="col-md-4 animate-box about-row-img" data-animate-effect="fadeInUp">
                <h2 className="section-title" style={{ visibility: 'hidden' }}>title</h2>
                <p className="about-title" style={{ visibility: 'hidden' }}>title</p>
                <div className="about-img">
                  {/* <!-- Logo --> */}
                  <div className="logo">
                    <img src="https://res.cloudinary.com/bizamall/image/upload/v1710956953/hug/assets/home/about_logo_pcw4dd.png" className="logo-img" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row line">
              <img src="https://res.cloudinary.com/bizamall/image/upload/v1710956944/hug/assets/home/about_line_puqoks.png" />
            </div>
            <div className="row text-left">
              <div className="mt-30 mb-30 col-md-12 text-left" data-animate-effect="fadeInUp">
                <p className="about-content2">
                  허그 소프트는 10년 전, 허그 매니지먼트라는 스타트업에서 시작했습니다. 허그 매니지먼트는 개발에 익숙하지 않은 일반 클라이언트의 담당 기술 매니저가 되어 맞춤 개발 대행 서비스를 제공하는 사업을 해왔습니다. 그 시간동안 다음과 같이 허그만의 차별화 강점을 키울 수 있었고, 이를 동력삼아 허그 소프트로 새로이 도약하려 합니다.
                </p>
              </div>
            </div>
            <div className="row about-list" style={{ paddingLeft: '5%' }}>
              <div className="numb">01</div>
              <span className="about-content">
                프로그램에 대한 전문적인 지식이 없어도 소통할 수 있는 고객 중심의 친절한 서비스 마인드
              </span>
            </div>
            <div className="row about-list" style={{ paddingLeft: '10%' }}>
              <div className="numb">02</div>
              <span className="about-content">
                고객의 니즈를 정확하게 파악하고, 이를 바탕으로 프로젝트를 완성시키는 기획의 전문성
              </span>
            </div>
            <div className="row about-list" style={{ paddingLeft: '15%' }}>
              <div className="numb">03</div>
              <span className="about-content">
                개발자들의 수준을 가늠하는 통찰력으로,  뛰어난 개발자들을 선별 영입하여 수준 높은 Man Power 보유
              </span>
            </div>
          </div>
        </section>
        {/* <!-- Services --> */}
        <section className="services section-padding services-mobile">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-left">
                <h2 className="section-title">OUR <span>SERVICES</span></h2>
              </div>
            </div>
            <div className="row text-left">
              <div className="col-md-4">
                <div className="item">
                  <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960196/hug/assets/home/blockchain_core_ken456.png" alt="" />
                  <h5>BLOCKCHAIN CORE</h5>
                  <div className="line"></div>
                  <div className="services-content">
                    <ul>
                      <li>블록체인 노드 구축</li>
                      <li>신규 가상화폐 개발 / 시장조사 및 플랜</li>
                      <li>백서 작성 : 코인 정책 및 블록체인 시스템 연계 - 알고리즘 및 구조화</li>
                      <li>윈도우 및 모바일 전자지갑 개발</li>
                      <li>블록체인 탐색기 / RPC 통신 모듈 개발</li>
                    </ul>
                    <div className="numb">01</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960254/hug/assets/home/chypto_currency_solution_wjfq5i.png" alt="" />
                  <h5>CRYPTO CURRENCY SOLUTION</h5>
                  <div className="line"></div>
                  <div className="services-content">
                    <ul>
                      <li>가상화폐 거래소/교환소 개발</li>
                      <li>가상화폐 P2P 거래소 개발</li>
                      <li>가상화폐 PAY 솔루션 개발</li>
                      <li>외부 연동 Restful Webservice(API) 모듈 개발</li>
                    </ul>
                    <div className="numb">02</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960210/hug/assets/home/ai_big_data_zbj2he.png" alt="" />
                  <h5>AI · BIG DATA</h5>
                  <div className="line"></div>
                  <div className="services-content">
                    <ul>
                      <li>최신 통계, 데이터마이닝 및 머신러닝 기법</li>
                      <li>자가학습을 통한 지속적인 품질 향상</li>
                      <li>기계 독해(MRC), 텍스트 분류(XDC)</li>
                      <li>자연어 이해(NLU)</li>
                      <li>문서 인식, 챗봇</li>
                    </ul>
                    <div className="numb">03</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-left">
              <div className="col-md-4">
                <div className="item">
                  <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960214/hug/assets/home/web_gkv5kx.png" alt="" />
                  <h5>WEB</h5>
                  <div className="line"></div>
                  <div className="services-content">
                    <ul>
                      <li>기업 홈페이지 제작</li>
                      <li>웹 크롤링 및 HTML 파싱</li>
                      <li>다양한 OS, 플랫폼에서의 웹서버 구축</li>
                      <li>강력한 성능의 WAS</li>
                      <li>사용자 경험 기반 반응형 웹사이트</li>
                    </ul>
                    <div className="numb">04</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960249/hug/assets/home/app_oxksdi.png" alt="" />
                  <h5>APP</h5>
                  <div className="line"></div>
                  <div className="services-content">
                    <ul>
                      <li>P2P, B2B 등 전문 마켓 플랫폼</li>
                      <li>Social Commerce</li>
                      <li>SNS - Social Network Service</li>
                      <li>각종 Community 플랫폼</li>
                      <li>Live Chat 플랫폼</li>
                    </ul>
                    <div className="numb">05</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="item">
                  <img src="https://res.cloudinary.com/bizamall/image/upload/v1710960249/hug/assets/home/app_oxksdi.png" alt="" />
                  <h5>ERP</h5>
                  <div className="line"></div>
                  <div className="services-content">
                    <ul>
                      <li>경영정보 시스템 - 인사, 급여, 근태, 회계, 영업, 재고관리</li>
                      <li>생산공정 시스템 - 원자재 BOM, 불량률, 공정률</li>
                      <li>다양한 사무 자동화 시스템</li>
                    </ul>
                    <div className="numb">06</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Projects --> */}
        <section className="projects section-padding projects-mobile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="section-title text-left">Our <span>Projects</span></h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="owl-carousel owl-theme">
                  <div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.eventplus[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/eventplus">Event outsourcing</a></h6>
                        <h5><a href="/eventplus">행사 아웃소싱 플랫폼</a></h5>
                        <div className="line"></div> <a href="/eventplus"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.sasurae[0]}alt="" /> </div>
                      <div className="con">
                        <h6><a href="/sasurae">Real-time video lecture</a></h6>
                        <h5><a href="/sasurae">실시간 화상 강의 솔루션</a></h5>
                        <div className="line"></div> <a href="/sasurae"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.togethertraining[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/togethertraining">group training service</a></h6>
                        <h5><a href="/togethertraining">그룹 트레이닝 서비스 플랫폼</a></h5>
                        <div className="line"></div> <a href="/togethertraining"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.ssoc[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/ssoc">community platform</a></h6>
                        <h5><a href="/ssoc">커뮤니티 플랫폼 서비스</a></h5>
                        <div className="line"></div> <a href="/ssoc"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.fitdoc[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/fitdoc">b2b subscribe solution</a></h6>
                        <h5><a href="/fitdoc">구독형 운동처방 서비스</a></h5>
                        <div className="line"></div> <a href="/fitdoc"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.myauth[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/myauth">Decentralized identity</a></h6>
                        <h5><a href="/myauth">DID 신원 인증 솔루션</a></h5>
                        <div className="line"></div> <a href="/myauth"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.game[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/game">web using record api</a></h6>
                        <h5><a href="/game">게임 API 연동 대회 홈페이지</a></h5>
                        <div className="line"></div> <a href="/game"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.naughtybomb[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/naughtybomb">ai photo conversion</a></h6>
                        <h5><a href="/naughtybomb">AI 사진 변환 솔루션</a></h5>
                        <div className="line"></div> <a href="/naughtybomb"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.tame[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/tame">tablet order integration</a></h6>
                        <h5><a href="/tame">매장 주문 통합 관리 솔루션</a></h5>
                        <div className="line"></div> <a href="/tame"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.elearning[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/cryptoCurrency_learning_platform_solution">E-Learning Platform</a></h6>
                        <h5><a href="/cryptoCurrency_learning_platform_solution">암호화폐 기반 온라인 강의 플랫폼</a></h5>
                        <div className="line"></div> <a href="/cryptoCurrency_learning_platform_solution"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.nft[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/nft_dex_solution">NFT DEX Solution</a></h6>
                        <h5><a href="/nft_dex_solution">NFT 거래소 솔루션</a></h5>
                        <div className="line"></div> <a href="/nft_dex_solution"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.marketplace[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/cryptoCurrency_online_marketplace_solution">online marketplace</a></h6>
                        <h5><a href="/cryptoCurrency_online_marketplace_solution">암호화폐 기반 오픈마켓 솔루션</a></h5>
                        <div className="line"></div> <a href="/cryptoCurrency_online_marketplace_solution"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.marketing[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/cryptoCurrency_marketing_solution">marketing solution</a></h6>
                        <h5><a href="/cryptoCurrency_marketing_solution">암호화폐 마케팅 솔루션</a></h5>
                        <div className="line"></div> <a href="/cryptoCurrency_marketing_solution"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.wallet[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/cryptocurrency_wallet_solution">Wallet solution</a></h6>
                        <h5><a href="/cryptocurrency_wallet_solution">암호화폐 지갑 솔루션</a></h5>
                        <div className="line"></div> <a href="/cryptocurrency_wallet_solution"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.exchange[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/cryptocurrency_exchange_solution">exchange solution</a></h6>
                        <h5><a href="/cryptocurrency_exchange_solution">암호화폐 거래소 솔루션</a></h5>
                        <div className="line"></div> <a href="/cryptocurrency_exchange_solution"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={images.mainnet[0]} alt="" /> </div>
                      <div className="con">
                        <h6><a href="/blockchain_mainnet_development">BlockChain Mainnet</a></h6>
                        <h5><a href="/blockchain_mainnet_development">블록체인 메인넷 구축</a></h5>
                        <div className="line"></div> <a href="/blockchain_mainnet_development"><i className="ti-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div >
    </>
  );
}