import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { useEffect, useState } from "react";
import $ from "jquery";
import { portfolioListsState, windowMaxWidthState } from '../../states/states';

export function PrevNextProjects({ preIndex, nextIndex }) {
   const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
   const [windowMaxWidth, setWindowMaxWidth] = useRecoilState(windowMaxWidthState);
   const [windowWidth, setWindowWidth] = useState($(window).width());

   return (
      <>
         {/* <!-- Prev-Next Projects --> */}
         < section className="projects-prev-next" >
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <div className="d-sm-flex align-items-center justify-content-between">
                        <div className="projects-prev-next-left text-left" style={{ flex: 1 }}>
                           <a href={portfolioLists[preIndex]}> <i className="ti-arrow-left"></i> {windowWidth > windowMaxWidth ? 'Previous Project' : 'PREV'}</a>
                        </div> <a href="/portfolio" style={{ flex: 1 }}><i className="ti-layout-grid3-alt"></i></a>
                        <div className="projects-prev-next-right text-right" style={{ flex: 1 }}>
                           <a href={portfolioLists[nextIndex]}> {windowWidth > windowMaxWidth ? 'Next Project' : 'NEXT'}  <i className="ti-arrow-right"></i></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section >
      </>
   );
}