import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { useEffect, useState } from "react";
import $ from "jquery";
import { portfolioListsState, windowMaxWidthState } from '../states/states';
import { HeaderBanner } from "../Layout/Header_Banner";

export function About() {
   const [windowMaxWidth, setWindowMaxWidth] = useRecoilState(windowMaxWidthState);
   const [windowWidth, setWindowWidth] = useState($(window).width());

   return (
      <>
         {/* <!-- Content --> */}
         <div className="content-wrapper">
            {/* <!-- Lines --> */}
            <section className="content-lines-wrapper">
               <div className="content-lines-inner">
                  <div className="content-lines"></div>
               </div>
            </section>
            <HeaderBanner />
            {/* <!-- About --> */}
            <section className="about section-padding2 about-mobile">
               <div className="container">
                  <div className="row" style={{ textAlign: 'start' }}>
                     <div className="col-md-8 mb-30 animate-box" data-animate-effect="fadeInUp">
                        <h2 className="section-title">About <span>HUG SOFT</span></h2>
                        <p className="about-title">[신뢰를 바탕으로 최상의 효율을 추구하다.]</p>
                        <p className="about-content">
                           {windowWidth > windowMaxWidth ?
                              <>
                                 <p>일의 주체는 사람이니, 사람의 성품을 살핌이 모든 성공의 첫걸음이라 생각합니다.</p>
                                 <p>백장의 계약서보다 수천개의 계약 조항보다 사람이 행하는 신뢰(信賴) 하나가 더 가치 있다고 생각하며,</p>
                                 <p>그 신뢰를 증명하기 위해 책임이란 무게를 짊어지고 무던히 걸어왔습니다. 신뢰있는 사람과의 약속 자체가</p>
                                 <p style={{ marginBottom: 30 }}>곧 보증된 계약서임을 10년의 기간동안 증명하였고, 그렇게 쌓아온 신뢰가  새로운 성장 동력이 되었습니다.</p>
                              </> :
                              <>
                                 <p style={{ marginBottom: 30 }}>
                                    일의 주체는 사람이니, 사람의 성품을 살핌이 모든 성공의 첫걸음이라 생각합니다.
                                    백장의 계약서보다 수천개의 계약 조항보다 사람이 행하는 신뢰(信賴) 하나가 더 가치 있다고 생각하며,
                                    그 신뢰를 증명하기 위해 책임이란 무게를 짊어지고 무던히 걸어왔습니다.
                                    신뢰있는 사람과의 약속 자체가 곧 보증된 계약서임을 10년의 기간동안 증명하였고,
                                    그렇게 쌓아온 신뢰가  새로운 성장 동력이 되었습니다.
                                 </p>
                              </>
                           }
                           <p>신뢰를 바탕으로 성공을 함께 설계하는 신의(信義)있는 파트너가 되겠습니다.</p>
                        </p>
                     </div>
                     <div className="col-md-4 animate-box about-row-img" data-animate-effect="fadeInUp">
                        <h2 className="section-title" style={{ visibility: 'hidden' }}>title</h2>
                        <p className="about-title" style={{ visibility: 'hidden' }}>title</p>
                        <div className="about-img">
                           {/* <!-- Logo --> */}
                           <div className="logo">
                              <img src="https://res.cloudinary.com/bizamall/image/upload/v1710956953/hug/assets/home/about_logo_pcw4dd.png" className="logo-img" alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="row line">
                     <img src="https://res.cloudinary.com/bizamall/image/upload/v1710956944/hug/assets/home/about_line_puqoks.png" />
                  </div>
                  <div className="row text-right">
                     <div className="mt-30 mb-30 col-md-12 text-left" data-animate-effect="fadeInUp">
                        <p className="about-content2">
                              허그 소프트는 10년 전, 허그 매니지먼트라는 스타트업에서 시작했습니다. 허그 매니지먼트는 개발에 익숙하지 않은 일반 클라이언트의 담당 기술 매니저가 되어 맞춤 개발 대행 서비스를 제공하는 사업을 해왔습니다. 그 시간동안 다음과 같이 허그만의 차별화 강점을 키울 수 있었고, 이를 동력삼아 허그 소프트로 새로이 도약하려 합니다.
                        </p>
                     </div>
                  </div>
                  <div className="row about-list" style={{ paddingLeft: '5%' }}>
                     <div className="numb">01</div>
                     <span className="about-content">
                        프로그램에 대한 전문적인 지식이 없어도 소통할 수 있는 고객 중심의 친절한 서비스 마인드
                     </span>
                  </div>
                  <div className="row about-list" style={{ paddingLeft: '10%' }}>
                     <div className="numb">02</div>
                     <span className="about-content">
                        고객의 니즈를 정확하게 파악하고, 이를 바탕으로 프로젝트를 완성시키는 기획의 전문성
                     </span>
                  </div>
                  <div className="row about-list" style={{ paddingLeft: '15%' }}>
                     <div className="numb">03</div>
                     <span className="about-content">
                        개발자들의 수준을 가늠하는 통찰력으로,  뛰어난 개발자들을 선별 영입하여 수준 높은 Man Power 보유
                     </span>
                  </div>
               </div>
            </section>
            {/* <!-- Skill --> */}
            <section className="process section-padding2 about-process about-process-mobile">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2 className="section-title text-left">DEVELOPER <span>SKILL POWER</span> <span style={{ fontSize: 20 }}>(TECH STACK)</span></h2>
                     </div>
                  </div>
                  <div className="row text-center mt-60 mb-60">
                     <div className="col-md-3">
                        <div className="item first">
                           <div className="cont">
                              <h6 className="skill-title">Front-End</h6>
                              <div className="line"></div>
                              <ul>
                                 <li>React</li>
                                 <li>React-Native / Expo Library</li>
                                 <li>Vue.js / Swiper.js / C3.js</li>
                                 <li>Cordova</li>
                                 <li>Ios&Android Native</li>
                                 <li>Wordpress</li>
                              </ul>
                              <h3>01</h3> </div>
                        </div>
                     </div>
                     <div className="col-md-3">
                        <div className="item odd mb-md50">
                           <div className="cont">
                              <h3>02</h3>
                              <h6 className="skill-title">Back-End</h6>
                              <div className="line"></div>
                              <ul>
                                 <li>Node.js / fastify / Express.js</li>
                                 <li>PHP / Laravel Framework</li>
                                 <li>Phython / Django</li>
                                 <li>Golang</li>
                                 <li>Load Balancing</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-3">
                        <div className="item">
                           <div className="cont">
                              <h3>03</h3>
                              <h6 className="skill-title">DevOps</h6>
                              <div className="line"></div>
                              <ul>
                                 <li>GCP / AWS / AlibabaCloud / MS Azure</li>
                                 <li>BlockChain <br /> (ETH, BTC, XRP, LTC, EOS, KLAY)</li>
                                 <li>C / C++ / C# / Java / Python</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-3">
                        <div className="item odd">
                           <div className="cont">
                              <h3>04</h3>
                              <h6 className="skill-title">DBMS</h6>
                              <div className="line"></div>
                              <ul>
                                 <li>MySQL / MariaDB</li>
                                 <li>MongoDB / Redis</li>
                                 <li>ORACLE</li>
                                 <li>Clustering</li>
                                 <li>Replication</li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </>
   );
}