import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';

export const portfolioListsState = atom({
   key: 'portfolioListsState', // unique ID (with respect to other atoms/selectors)
   default: [
      'eventplus', 'togethertraining', 'sasurae', 'ssoc', 'fitdoc', 'myauth',
      'game', 'naughtybomb', 'tame', 'nft_dex_solution', 'cryptoCurrency_learning_platform_solution',
      'cryptoCurrency_online_marketplace_solution', 'cryptoCurrency_marketing_solution',
      'cryptocurrency_exchange_solution', 'cryptocurrency_wallet_solution',
      'blockchain_mainnet_development', 
   ],
});

export const windowMaxWidthState = atom({
   key: 'windowMaxWidthState', // unique ID (with respect to other atoms/selectors)
   default: 480
});