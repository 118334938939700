import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { portfolioListsState } from '../../states/states';
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function Ssock({ index }) {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
      {/* <!-- Content --> */}
      <div className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </section>
        <HeaderBanner />
        {/* <!-- Project Page  --> */}
        <section className="section-padding2 portfolio-info portfolio-info-mobile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="section-title2 text-left">커뮤니티 플랫폼 서비스</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <p className="text-left">
                  SSOC은 Community Platform B2B Solution으로 개발된 Mobile Application입니다. 
                  <br/><br/>
                  모임 관리부터 운영까지 다양한 고민을 가진 모임 대표를 Targeting하여 서비스가 세팅되었습니다.<br/>
                  번거로운 모임 관리, 회원들의 모임 이탈, 모임 유지비용에 대한 부담을 선순환 구조로 해결하기 위한 시스템을 제공하고 있습니다.
                  안드로이드/IOS APP으로 개발되었으며, 전체적인 서비스 관리를 위한 웹 Admin 또한 함께 개발되었습니다.
                  프로토 타입 개발기간은 2달이었으며, 지속적으로 기능 추가가 이루어지고 있습니다.
                </p>
              </div>
              <div className="col-md-4 text-left">
                <p><b>Type : </b> Community Platform</p>
                <p><b>Release Date : </b> 2022.12. ~ 2023.04.</p>
                <p><b>Service : </b> Mobile App / Web Admin</p>
                <p><b>Client : </b> SSOC</p>
                <p><b>링크 : </b> <a className="portfolio-link" href="https://play.google.com/store/apps/details?id=com.hug.ssock" target="_blank">LINK</a></p>
              </div>
            </div>
            <div className="row mt-30">
              {
                images.ssoc.map(img => (
                  <div className="col-md-6 gallery-item">
                    <a href={img} title="SSOC" className="img-zoom">
                      <div className="gallery-box">
                        <div className="gallery-img"> <img src={img} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                      </div>
                    </a>
                  </div>
                ))
              }
            </div>
          </div>
        </section>
        <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
      </div>
    </>
  );
}