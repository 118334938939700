import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { portfolioListsState } from '../../states/states';
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function Game({ index }) {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
    {/* <!-- Content --> */}
    <div className="content-wrapper">
      {/* <!-- Lines --> */}
      <section className="content-lines-wrapper">
      <div className="content-lines-inner">
          <div className="content-lines"></div>
      </div>
      </section>
      <HeaderBanner />
      {/* <!-- Project Page  --> */}
      <section className="section-padding2 portfolio-info portfolio-info-mobile">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title2 text-left">게임 API 연동 대회 홈페이지</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p className="text-left">
                CK리그 홈페이지는 게임 API 연동으로 제작된 대회 홈페이지 입니다. 개발 기간은 1달 반입니다.
                <br/><br/>
                ■ 전적 API에서 가져온 다양한 정보로 대회 랭킹 실시간 출력!<br/>
                ■ 대형 게임 클랜에서 온라인 게임 리그를 개최하고자 제작된 홈페이지
                <br/><br/>
                □ 다양한 기능을 제공합니다<br/>
                대회 소개 페이지 / 대회 소식 게시판 / 하이라이트 영상 게시판 / 시즌 별 실시간 팀 랭킹 / 시즌 별 선수 랭킹 (킬·딜 etc) / 라운드 별 팀 랭킹 / 명예의 전당
              </p>
            </div>
            <div className="col-md-4 text-left">
              <p><b>Type : </b>  Web using record API</p>
              <p><b>Release Date : </b> 2021.05. ~ 2021.06.</p>
              <p><b>Service : </b>  PC Web / Admin</p>
              <p><b>Client : </b>  CLAN CK</p>
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-md-6 gallery-item">
              <a href={images.game[0]} title="CK리그 홈페이지" className="img-zoom">
                <div className="gallery-box">
                  <div className="gallery-img"> <img src={images.game[0]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                </div>
              </a>
            </div>
            <div className="col-md-6 gallery-item">
              <a href={images.game[1]} title="CK리그 홈페이지" className="img-zoom">
                <div className="gallery-box">
                  <div className="gallery-img"> <img src={images.game[1]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        </section>
      <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
    </div>
    </>
  );
}