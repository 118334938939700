import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { useEffect, useMemo, useState } from "react";
import { portfolioListsState, windowMaxWidthState } from '../states/states';
//ChannelService 참조
import ChannelService from "../services/ChannelService";
import $ from "jquery";

export function Footer() {
    const [windowMaxWidth, setWindowMaxWidth] = useRecoilState(windowMaxWidthState);
    const [windowWidth, setWindowWidth] = useState($(window).width());

    ChannelService.loadScript();

    const date = Date.now();
    const guestId = !window.sessionStorage.getItem('channel-guest')
        ? `guest-${date}` : window.sessionStorage.setItem('channel-guest', date);

    ChannelService.boot({
        pluginKey: '2021f55e-921c-4a6b-a84c-ec8a78f823bd',
        memberId: guestId,
        profile: null
    });

    return (
        <>
            {/* <!-- Footer --> */}
            {/* <!-- Promo video - Testiominals --> */}
            <section className="testimonials">
                {/* <div className="background bg-img section-padding pb-0"
                data-background={windowWidth > windowMaxWidth ? `img/HUG/home/footer.png` : `img/HUG/home/footer_mobile.png`}
                style={{ height: 400 }}
                data-overlay-dark="0">
                </div> */}
                <img className="section-padding pb-0" src={windowWidth > windowMaxWidth ? `img/HUG/home/footer.png` : `img/HUG/home/footer_mobile.png`}/>
            </section>
            <footer className="main-footer dark footer-mobile">
                <div className="container text-left">
                    <div className="row">
                        {windowWidth > windowMaxWidth ?
                            <>
                                <div className="col-md-3">
                                <div className="item fotcont">
                                    <div className="fothead">
                                        <h6>Company</h6>
                                    </div>
                                    <p>HUG SOFT</p>
                                </div>
                                </div>
                                <div className="col-md-3">
                                <div className="item fotcont">
                                    <div className="fothead">
                                        <h6>CEO</h6>
                                    </div>
                                    <p>JOSEPH KO</p>
                                </div>
                                </div>
                                <div className="col-md-3">
                                <div className="item fotcont">
                                    <div className="fothead">
                                        <h6>Email</h6>
                                    </div>
                                    <p>info@hugsoft.net</p>
                                </div>
                                </div>
                                <div className="col-md-3">
                                <div className="item fotcont">
                                    <div className="fothead">
                                        <h6>Business Number</h6>
                                    </div>
                                    <p>530-88-02749</p>
                                </div>
                                </div>
                            </> :
                            <>
                                <div className="col-md-4">
                                <div className="item fotcont">
                                    <div className="fothead">
                                        <h6>Company</h6>
                                    </div>
                                    <p>HUG SOFT</p>
                                </div>
                                <div className="item fotcont">
                                    <div className="fothead">
                                        <h6>CEO</h6>
                                    </div>
                                    <p>JOSEPH KO</p>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div className="item fotcont">
                                    <div className="fothead">
                                        <h6>Email</h6>
                                    </div>
                                    <p>info@hugsoft.net</p>
                                </div>
                                <div className="item fotcont">
                                    <div className="fothead">
                                        <h6>Business Number</h6>
                                    </div>
                                    <p>530-88-02749</p>
                                </div>
                                </div>
                            </>
                        }
                    </div>
                    {/* <div className="coupang-banner row">
                        <a href="https://link.coupang.com/a/w0et2" target="_blank" referrerpolicy="unsafe-url">
                            <img src="https://ads-partners.coupang.com/banners/603472?subId=&traceId=V0-301-879dd1202e5c73b2-I603472&w=728&h=90" alt="" />
                        </a>
                        {windowWidth > windowMaxWidth ?
                            <p>해당 배너는 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를 제공받고 있습니다.</p>
                            :
                        <p>해당 배너는 쿠팡 파트너스 활동의 일환으로, <br /> 이에 따른 일정액의 수수료를 제공받고 있습니다.</p>
                    }
                </div> */}
                </div>
                <div className="sub-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12" style={{ marginLeft: 'auto' }}>
                                <div className="text-center">
                                <p>© 2022 HUGSOFT. All Rights Reserved.</p>
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <p className="right"><a href="#">Terms</a></p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}