import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { portfolioListsState } from '../../states/states';
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function CryptoCurrencyMarketingSolution({ index }) {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
      {/* <!-- Content --> */}
      <div className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </section>
        <HeaderBanner />
        {/* <!-- Project Page  --> */}
        <section className="section-padding2 portfolio-info portfolio-info-mobile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="section-title2 text-left">암호화폐 마케팅 솔루션</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <p className="text-left">
                  암호화폐 마케팅을 위해 개발된 솔루션을 구축 및 운영하였습니다.
                  개발에 소요된 기간은 2개월 입니다.
                  <br/><br/>
                  회원 간의 추천인 제도를 통해 서비스가 운영되며, 각 회원은 앱 내에 암호화폐를 입출금 및 스테이킹 하며 자유롭게 재테크 가능합니다. 또한 추천인 제도로 회원 유치 시 운영사의 암호화폐를 보상으로 지급함으로써, 마케팅을 더욱 활성화하고 바람직한 코인 생태계를 구축했습니다. 
                  <br/><br/>
                  기존 마케팅 앱들은 계정의 트리 구조 관계에 따른 연산 지연으로 발생하는 심각하게 긴 레이턴시(latency)와 그로 인한 고객 화면단에서의 UX 저하 현상을 고질적인 문제로 가지고 있습니다. 이런 문제 사항들을 DB 스키마 설계 수정, 핵심 연산 알고리즘 교체, 쿼리 튜닝, 작업 스케줄러 Crontab 등 본사의 다양한 기술과 노하우로 대폭 개선하여 좀 더 완벽한 암호화폐 마케팅 솔루션을 제공하였습니다.
                  <br/><br/>
                  포트폴리오에 관련된 구체적인 내용은 보안 상의 이유로 오프라인 미팅을 통해서만 제공 가능합니다.
                </p>
              </div>
              <div className="col-md-4 text-left">
                <p><b>Type : </b> Marketing Solution</p>
                <p><b>Release Date : </b> 2020.06. ~ 2021.06.</p>
                <p><b>Client : </b> Anonymous</p>
                {/* <p><b>Distribution : </b> <a className="portfolio-link" href="https://sba-world.com/distribution/android">LINK</a></p> */}
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-6 gallery-item">
                <a href={images.marketing[0]} title="CryptoCurrency Marketing Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.marketing[0]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.marketing[1]} title="CryptoCurrency Marketing Solution" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.marketing[1]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
      </div>
    </>
  );
}