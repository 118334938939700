import { useEffect } from "react";
import { HeaderBanner } from "../Layout/Header_Banner";
import $ from "jquery";

export function FAQ() {

   useEffect(() => {
      // Accordion Box
      if ($(".accordion-box").length) {
         $(".accordion-box").on("click", ".acc-btn", function () {
            var outerBox = $(this).parents(".accordion-box");
            var target = $(this).parents(".accordion");

            if ($(this).next(".acc-content").is(":visible")) {
               //return false;
               $(this).removeClass("active");
               $(this).next(".acc-content").slideUp(300);
               $(outerBox).children(".accordion").removeClass("active-block");
            } else {
               $(outerBox).find(".accordion .acc-btn").removeClass("active");
               $(this).addClass("active");
               $(outerBox).children(".accordion").removeClass("active-block");
               $(outerBox).find(".accordion").children(".acc-content").slideUp(300);
               target.addClass("active-block");
               $(this).next(".acc-content").slideDown(300);
            }
         });
      }
   }, [])

   return (
      <>
         {/* <!-- Content --> */}
         <div className="content-wrapper">
            {/* <!-- Lines --> */}
            <section className="content-lines-wrapper">
               <div className="content-lines-inner">
                  <div className="content-lines"></div>
               </div>
            </section>
            <HeaderBanner />
            {/* <!-- Faqs --> */}
            <section className="section-padding2 text-left faqs-mobile">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <h2 className="section-title"><span>Faqs</span></h2>
                     </div>
                     <div className="col-md-6">
                        <ul className="accordion-box">
                           <li className="accordion block active-block">
                              <div className="acc-btn"><span className="count">1.</span> HUG SOFT에서 제공하는 서비스는 어떤 것들이 있나요?</div>
                              <div className="acc-content">
                                 <div className="content">
                                    <div className="text">Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.</div>
                                 </div>
                              </div>
                           </li>
                           <li className="accordion block">
                              <div className="acc-btn"><span className="count">2.</span> 프로젝트 제작 의뢰시 준비할 것이 있나요?</div>
                              <div className="acc-content">
                                 <div className="content">
                                    <div className="text">Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.</div>
                                 </div>
                              </div>
                           </li>
                           <li className="accordion block">
                              <div className="acc-btn"><span className="count">3.</span> 보통 제작 기간은 어느 정도 인가요?</div>
                              <div className="acc-content">
                                 <div className="content">
                                    <div className="text">Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.</div>
                                 </div>
                              </div>
                           </li>
                           <li className="accordion block">
                              <div className="acc-btn"><span className="count">4.</span> 견적비용 책정은 어떤 방식으로 이루어지나요?</div>
                              <div className="acc-content">
                                 <div className="content">
                                    <div className="text">Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.</div>
                                 </div>
                              </div>
                           </li>
                           <li className="accordion block">
                              <div className="acc-btn"><span className="count">5.</span> 유지보수는 어떠한 방식으로 이루어지나요?</div>
                              <div className="acc-content">
                                 <div className="content">
                                    <div className="text">Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.</div>
                                 </div>
                              </div>
                           </li>
                        </ul>
                     </div>
                     <div className="col-md-6">
                        <ul className="accordion-box">
                           <li className="accordion block">
                              <div className="acc-btn"><span className="count">1.</span> Creative Design Structures
                              </div>
                              <div className="acc-content">
                                 <div className="content">
                                    <div className="text">Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.</div>
                                 </div>
                              </div>
                           </li>
                           <li className="accordion block">
                              <div className="acc-btn"><span className="count">2.</span> 3D Modellling Structures</div>
                              <div className="acc-content">
                                 <div className="content">
                                    <div className="text">Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.</div>
                                 </div>
                              </div>
                           </li>
                           <li className="accordion block">
                              <div className="acc-btn"><span className="count">3.</span> Creative Modern Design</div>
                              <div className="acc-content">
                                 <div className="content">
                                    <div className="text">Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.</div>
                                 </div>
                              </div>
                           </li>
                           <li className="accordion block">
                              <div className="acc-btn"><span className="count">4.</span> Modern Urban Structures
                              </div>
                              <div className="acc-content">
                                 <div className="content">
                                    <div className="text">Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.</div>
                                 </div>
                              </div>
                           </li>
                           <li className="accordion block">
                              <div className="acc-btn"><span className="count">5.</span> Modern Building Structures</div>
                              <div className="acc-content">
                                 <div className="content">
                                    <div className="text">Architecture viverra tristique justo duis vitae diam neque nivamus aestan ateuene artines aringianu atelit finibus viverra nec lacus. Nedana theme erodino setlie suscipe no tristique.</div>
                                 </div>
                              </div>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </>
   );
}