import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { HeaderBanner } from "../../Layout/Header_Banner";
import { portfolioListsState } from '../../states/states';
import { PrevNextProjects } from './prev_next_projects';
import { images } from '../../constants/images';

export function Tame({ index }) {
  const [portfolioLists, setPortfolioLists] = useRecoilState(portfolioListsState);
  return (
    <>
      {/* <!-- Content --> */}
      <div className="content-wrapper">
        {/* <!-- Lines --> */}
        <section className="content-lines-wrapper">
          <div className="content-lines-inner">
            <div className="content-lines"></div>
          </div>
        </section>
        <HeaderBanner />
        {/* <!-- Project Page  --> */}
        <section className="section-padding2 portfolio-info portfolio-info-mobile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="section-title2 text-left">주문 통합 관리 솔루션</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <p className="text-left">
                  TABLET MENU ORDER은 Order Intergration Platform으로 개발된 Mobile Application입니다.<br/>
                  고객용 주문앱, 주방용 관리앱, 홀용 관리앱, POS 앱 및 Web Admin으로 구성되어 있습니다.
                  <br/><br/>
                  점원 응대 없이 비대면으로 고객이 직접 주문하면 홀, 주방, POS 기기에 주문, 조리, 서빙현황이 자동으로 동기화되어 한 눈에 매장 상황을 파악할 수 있습니다. 주문 유형별/시간대별/메뉴별 매출 데이터를 쉽게 확인 가능하며, 관리자 모드에서 매장과 영업에 관련된 상태를 쉽게 수정할 수 있습니다.
                </p>
              </div>
              <div className="col-md-4 text-left">
                <p><b>Type : </b> Tablet Order Integration</p>
                <p><b>Date : </b>  2022.06. ~ 2022.09.</p>
                <p><b>Service : </b> Mobile App / Web Admin</p>
                <p><b>Client : </b> Anonymous</p>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-6 gallery-item">
                <a href={images.tame[0]} title="TABLET MENU ORDER (TAME)" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.tame[0]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.tame[1]} title="TABLET MENU ORDER (TAME)" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.tame[1]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.tame[2]} title="TABLET MENU ORDER (TAME)" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.tame[2]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.tame[3]} title="TABLET MENU ORDER (TAME)" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.tame[3]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.tame[4]} title="TABLET MENU ORDER (TAME)" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.tame[4]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 gallery-item">
                <a href={images.tame[5]} title="TABLET MENU ORDER (TAME)" className="img-zoom">
                  <div className="gallery-box">
                    <div className="gallery-img"> <img src={images.tame[5]} className="img-fluid mx-auto d-block" alt="work-img" /> </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <PrevNextProjects preIndex={index > 0 ? index - 1 : portfolioLists.length - 1} nextIndex={index < portfolioLists.length - 1 ? index + 1 : 0} />
      </div>
    </>
  );
}