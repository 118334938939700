import { RecoilRoot, atom, selector, useRecoilState } from 'recoil';
import { useEffect, useMemo, useState } from "react";
import { portfolioListsState, windowMaxWidthState } from '../states/states';
import $ from "jquery";

export function Sidebar() {
   const [windowMaxWidth, setWindowMaxWidth] = useRecoilState(windowMaxWidthState);
   const [windowWidth, setWindowWidth] = useState($(window).width());
   return (
      <>
         {/* <!-- Slider --> */}
         <header className="header slider-fade">
            <div className="owl-carousel owl-theme">
               {/* <!-- The opacity on the image is made with "data-overlay-dark="number". You can change it using the numbers 0-9. --> */}
               <div className="text-right item bg-img" data-overlay-dark="0" data-background="https://res.cloudinary.com/bizamall/image/upload/v1710957668/hug/assets/home/home_banner_lescn9.png">
                  <div className="v-middle caption mt-30">
                     <div className="container">
                        <div className="row">
                           <div className="col-md-12 offset-md-5" style={{ marginLeft: 'auto' }}>
                              {windowWidth > windowMaxWidth ?
                                 <>
                                    <h1>INNOVATIVE CHANGE<br />
                                       WITH BLOCKCHAIN HUB, <span style={{ color: '#FFA553' }}>HUG</span></h1>
                                    <p>성공을 함께 설계하는 신의(信義)있는 파트너</p>
                                 </>
                                 :
                                 <>
                                    <h1 style={{ fontSize: 24 }}>INNOVATIVE CHANGE<br />
                                       WITH BLOCKCHAIN HUB, <span style={{ color: '#FFA553' }}>HUG</span></h1>
                                    <p>성공을 함께 설계하는 신의(信義)있는 파트너</p>
                                 </>
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="text-right item bg-img" data-overlay-dark="0" data-background={"https://res.cloudinary.com/bizamall/image/upload/v1710957325/hug/assets/home/banner2_az2nnf.png"}>
                  <div className="v-middle caption mt-30">
                     <div className="container">
                        <div className="row">
                           <div className="col-md-12 offset-md-5" style={{ marginLeft: 'auto', textShadow: '3px 4px 4px rgba(0, 0, 0, 0.32)' }}>
                              {windowWidth > windowMaxWidth ?
                                 <>
                                    <h1 style={{ fontFamily: 'MyLotteBold', fontWeight: 800, letterSpacing: '0.215em', lineHeight: '137%', fontSize: '2.5rem' }}>
                                       기존 기술에
                                       <span style={{ color: '#48C9F5', paddingLeft: '0.215em' }}>신기술</span>
                                       을 더하여<br />
                                       새로운 서비스로 더 높은
                                       {/* <div style={{ background: 'linear-gradient(to top, rgba(29, 195, 232, 0.53) 50%, transparent 40%)', lineHeight: '100%', display: 'inline-block' }}>고객가치 창출</div> */}
                                       <div style={{ position: 'relative', display: 'initial', marginLeft: '0.48em' }}>
                                          고객가치 창출
                                          <div style={{ position: 'absolute', width: '97%', height: '50%', background: 'rgba(29, 195, 232, 0.53)', bottom: 0, zIndex: -1, left: 0 }}></div>
                                       </div>
                                    </h1>
                                    <p style={{ marginRight: 0 }}>
                                       <img src="https://res.cloudinary.com/bizamall/image/upload/v1710957310/hug/assets/home/banner2_icon_efzmtf.png" style={{ width: 'auto', marginLeft: 'auto', marginTop: 38 }} />
                                    </p>
                                 </>
                                 :
                                 <>
                                    <h1 style={{ fontFamily: 'MyLotteBold', fontWeight: 800, letterSpacing: '0.215em', lineHeight: '142.5%', fontSize: 24 }}>
                                       기존 기술에<br />
                                       <span style={{ color: '#48C9F5', paddingLeft: '0.215em' }}>신기술</span>
                                       을 더하여<br />
                                       새로운 서비스로 <br />
                                       <div style={{ position: 'relative', display: 'initial' }}>더 높은 고객가치 창출
                                          <div style={{ position: 'absolute', width: '98%', height: '50%', background: 'rgba(29, 195, 232, 0.53)', bottom: 0, zIndex: -1, left: 0 }}></div>
                                       </div>
                                    </h1>
                                    <p style={{ marginRight: 0 }}>
                                       <img src="https://res.cloudinary.com/bizamall/image/upload/v1710957310/hug/assets/home/banner2_icon_efzmtf.png" style={{ width: '80%', marginLeft: 'auto' }} />
                                    </p>
                                 </>
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="text-right item bg-img" data-overlay-dark="0" data-background={'https://res.cloudinary.com/bizamall/image/upload/v1710957667/hug/assets/home/banner3_n0tfta.png'}>
                  <div className="v-middle caption mt-30">
                     <div className="container">
                        <div className="row">
                           <div className="col-md-12 offset-md-5" style={{ marginLeft: 'auto', textShadow: '3px 4px 4px rgba(0, 0, 0, 0.32)' }}>
                              {windowWidth > windowMaxWidth ?
                                 <>
                                    <h1 className='slider3' style={{ fontFamily: 'MyLotteBold', lineHeight: '128.5%', letterSpacing: '0.135em' }}>
                                       <span style={{ fontFamily: 'YouTubeSansMedium', fontSize: 60, letterSpacing: '0.06em', wordSpacing: '-0.25em' }}>HUG &nbsp;</span>
                                       <span style={{ background: 'linear-gradient(to top, #F09246 50%, transparent 40%)', lineHeight: '100%', fontFamily: 'MyLotteBold', letterSpacing: 0, wordSpacing: -5 }}>포 트 폴 리 오</span>
                                       를 확인하세요<br />
                                       <span style={{ color: '#FFA553', fontFamily: 'YouTubeSansMedium', fontSize: 60, letterSpacing: '0.06em' }}>HUG</span>
                                       는 다릅니다
                                    </h1>
                                    <p style={{ fontFamily: 'YouTubeSansMedium', letterSpacing: '0.09em', fontSize: 16 }}>블록체인 코어 개발은 물론 운영까지 동시에</p>
                                 </> :
                                 <>
                                    <h1 style={{ fontFamily: 'MyLotteBold', lineHeight: '142.5%', letterSpacing: '0.215em', fontSize: 27 }}>
                                       <span style={{ background: 'linear-gradient(to top, #F09246 50%, transparent 40%)', lineHeight: '100%', fontFamily: 'MyLotteBold', letterSpacing: 0, wordSpacing: -1, marginRight: 4 }}>포 트 폴 리 오</span>
                                       를 <br />
                                       확인하세요<br />
                                       <span style={{ color: '#FFA553', fontFamily: 'YouTubeSansMedium', fontSize: 36, letterSpacing: '0.05em' }}>HUG</span>
                                       는 다릅니다
                                    </h1>
                                    <p style={{ fontFamily: 'YouTubeSansMedium', lineHeight: '118.5%', letterSpacing: '0.09em', fontSize: 14 }}>블록체인 코어 개발은 물론 운영까지 동시에</p>
                                 </>
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- Corner --> */}
            <div className="hero-corner"></div>
            <div className="hero-corner3"></div>
            {/* <!-- Left Panel --> */}
            <div className="left-panel">
            </div>
         </header>
      </>
   );
}